import BaseApiService from "./BaseApiService";
import { AccountType, Bank, Currency, ServiceType } from '@/models'
import AppSettings from "@/AppSettings";
import store from "@/store";
import { CatalogCategory } from "@/models/Budget/BudgetCategory";

export default class CommonService extends BaseApiService {
    public constructor() {
        super()
        this.apiUrl = AppSettings.API_URL + 'commons/'
    }

    public GetCurrencies() {
        return this.get<Object, Currency[]>("currencies/?country=DO")
    }

    public GetBanks() {
        return this.get<Object, Bank>("banks/?country=DO")
    }

    public GetAccountTypes() {
        return this.get<Object, AccountType>("account_types/?country=DO")
    }

    public GetServices() {
        return this.get<Object, ServiceType[]>("service_types/?country=DO")
    }

    public GetCategories() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<Object, CatalogCategory[]>(`category/?subscription=${subscription}`)
    }
}