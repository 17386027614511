import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/budget'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      allowAnonymous: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/enrollment',
    name: 'Enrrolamiento',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Enrollment.vue')
  },
  {
    path: '/register',
    name: 'Registrar',
    meta: {
      allowAnonymous: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/confirm-registration',
    name: 'Confirma tu cuenta',
    meta: {
      allowAnonymous: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfirmRegistration.vue')
  },
  {
    path: '/password-recovery',
    name: 'Recuperar contraseña',
    meta: {
      allowAnonymous: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordRecovery.vue')
  },
  {
    path: '/password-recovery-2',
    name: 'Recuperar contraseña Token',
    meta: {
      allowAnonymous: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordRecovery2.vue')
  },
  {
    path: '/password-recovery-3',
    name: 'Registra tu nueva contraseña',
    meta: {
      allowAnonymous: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordRecovery3.vue')
  },
  {
    path: '/budget',
    name: 'Presupuesto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Budget.vue')
  },
  {
    path: '/help-desk',
    name: 'Help-Desk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HelpDesk.vue')
  },
  {
    path: '/assetsLiabilities',
    name: 'Activos y Pasivos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/assetsLiabilities.vue')
  },
  {
    path: '/account',
    name: 'Cuenta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountSummary.vue')
  },
  {
    path: '/plans',
    name: 'Planes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Plans.vue')
  },
  {
    path: '/payment-history',
    name: 'Historial de Pagos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PaymentHistory.vue')
  },
  {
    path: '/welcome-page',
    name: 'Bienvenida',
    meta: {
        allowAnonymous: true
    },
    component: () => import('../views/WelcomePage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//Logged navigation Guard
router.beforeEach(async (to, from, next) => {
  if (to.name?.toLowerCase() == 'dashboard') {
    let userHasAccessToDashboard: boolean = await store.dispatch("IdentityManagement/checkIfUserHasAccessToDashboard");

    if (!userHasAccessToDashboard) {
      next({ name: 'Presupuesto' });
      return;
    }
  }
  let userIsLoggedIn: boolean = await store.dispatch("IdentityManagement/checkIfUserIsLoggedIn");

  let allowAnonymous = to?.meta?.allowAnonymous;
  if (allowAnonymous || userIsLoggedIn) {
    next();
    return;
  }

  next({ name: 'Login' });
  // next();
})

export default router
