import { ActionContext } from "vuex";
import { BudgetSubCategory, CategoryType, BudgetCategory, BudgetSubCategoryEdit, BudgetTemplate, BudgetTemplateCategory, BudgetTemplateSubCategory } from "@/models/Budget/BudgetCategory";
import BudgetService from "@/services/BudgetService";
import { generateGUID } from "@/utils/HelperMethods";
import BudgetTransaction from "@/models/Budget/BudgetTransaction";
import BudgetConfiguration from "@/models/Budget/BudgetConfiguration";
import UserBudget from "@/models/Budget/UserBudget";
import { BankAccount, CreditCard, ServiceToPay } from "@/models/UserCatalog/UserCatalog"
import { ExpenseTransactionAccount } from "@/models/Catalog/Catalog"
import { Auth } from 'aws-amplify';
import CommonService from "@/services/CommonService";
import IdentityManagementService from "@/services/IdentityManagementService";
import CatalogService from "@/services/CatalogService";

interface BudgetStore {
    showNewCategoryModal: boolean,
    budgetIsLoading: boolean,
    categoryCatalog: BudgetCategory[],
    categoryCatalogIsLoading: boolean,
    changeId: string,
    showEnrollmentCategoriesModal: boolean,
    budgetTotalIncome: number,
    budgetTransactions: BudgetTransaction[],
    showProfileModal: boolean,
    showNewBudgetModal: boolean,
    budgetConfiguration: BudgetConfiguration | null,
    userBudgets: UserBudget[],
    activeBudget: UserBudget | null,
    showDummyDataInChart: boolean,
    enrollmentCategoriesLoaded: boolean,
    resetTransactionForm: boolean,
    userIncomeTransactionAccounts: BankAccount[],
    userExpenseTransactionAccounts: ExpenseTransactionAccount[],
    userPaymentTransactionAccount: CreditCard[],
    skipTutorial: boolean,
    invalidTransactionState: boolean,
    serviceToPay: ServiceToPay,
    budgetTypeTab: number,
    lastVisitedTab: number,
    lastVisitedSubTab: number,
    lastBudgetUsed: string,
    budgetTemplates: BudgetTemplate[];
}
const budgetService = new BudgetService();
const commonService = new CommonService()
const idService = new IdentityManagementService();
const catalogService = new CatalogService();

function initialState(): BudgetStore {
    return {
        showNewCategoryModal: false,
        budgetIsLoading: false,
        categoryCatalog: [],
        categoryCatalogIsLoading: true,
        changeId: "",
        showEnrollmentCategoriesModal: false,
        budgetTotalIncome: 0,
        budgetTransactions: [],
        showProfileModal: false,
        showNewBudgetModal: false,
        budgetConfiguration: null,
        userBudgets: [],
        activeBudget: null,
        showDummyDataInChart: false,
        enrollmentCategoriesLoaded: false,
        resetTransactionForm: false,
        userIncomeTransactionAccounts: [],
        userExpenseTransactionAccounts: [],
        userPaymentTransactionAccount: [],
        skipTutorial: false,
        invalidTransactionState: false,
        serviceToPay: {} as ServiceToPay,
        budgetTypeTab: 0,
        lastVisitedTab: 0,
        lastVisitedSubTab: 0,
        lastBudgetUsed: "",
        budgetTemplates: []
    }
}
// State object
const state = initialState()

// Getter functions
const getters = {
    showNewCategoryModal(state: BudgetStore) {
        return state.showNewCategoryModal;
    },
    getResetBudgetTransactionModal(state: BudgetStore) {
        return state.resetTransactionForm;
    },
    getUserBudgets(state: BudgetStore) {
        return state.userBudgets;
    },
    getBudgetTemplates(state: BudgetStore) {
        return state.budgetTemplates
    },
    getActiveBudget(state: BudgetStore) {
        return state.activeBudget;
    },
    budgetIsLoading(state: BudgetStore) {
        return state.budgetIsLoading;
    },
    getBudgetCategories(state: BudgetStore) {
        if (!state.activeBudget || state.activeBudget.categories?.length == 0) {
            return []
        }
        return state.activeBudget.categories?.map(cat => ({...cat, alias: cat.alias ? cat.alias : cat.category, type: typeof cat.type == 'object' ? cat.type.code.toUpperCase() : cat.type?.toUpperCase()}))
    },
    getChangeId(state: BudgetStore) {
        return state.changeId;
    },
    getShowEnrollmentCategoriesModal(state: BudgetStore) {
        return state.showEnrollmentCategoriesModal;
    },
    getShowProfileModal(state: BudgetStore) {
        return state.showProfileModal;
    },
    getShowNewBudgetModal(state: BudgetStore) {
        return state.showNewBudgetModal;
    },
    getCategoryCatalog(state: BudgetStore) {
        return state.categoryCatalog;
    },
    getCategoryCatalogByType(state: BudgetStore) {
        let filter = state.budgetTypeTab == 0 ? 'G' : 'I';
        return state.categoryCatalog.filter(c => {
            let code = typeof c.type == 'object' ? c.type?.code : c.type;
            if (filter == 'G' && code?.toLowerCase() == 'pago deudas') return c
            else if (filter == code) return c
        });
    },
    getCategoryCatalogBlank(state: BudgetStore) {
        return state.categoryCatalog.filter(c => typeof c.type == 'object' ? c.type?.code == 'blank' : c.type == 'blank');
    },
    getCategoryCatalogIsLoading(state: BudgetStore) {
        return state.categoryCatalogIsLoading;
    },
    getBudgetTotalIncomePresupuestado(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        if (budgetCategories.length == 0)
            return 0;
        
        let montos = budgetCategories
                        .filter(c => c.type == CategoryType.Income)
                        .map(c => c.subcategories)
                        .flat()
                        .map((a,b) => typeof a.budgeted == 'string' ? parseFloat(a.budgeted) * (+a.rate): a.budgeted * (+a.rate));

        var total = montos.length > 0
                ? montos.reduce((a, b) => a + b)
                : 0;

        return total;
    },
    getBudgetTotalExpensePresupuestado(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];

        if (budgetCategories.length == 0)
            return 0;
        let montos = budgetCategories
            .filter(c => typeof c.type == 'object' ? c.type?.code.toUpperCase() == CategoryType.Expense : c.type?.toUpperCase() == CategoryType.Expense)
            .map(c => c.subcategories)
            .flat()
            .map((a,b) => typeof a.budgeted == 'string' ? parseFloat(a.budgeted) * (+a.rate): a.budgeted * (+a.rate));

        var total = montos.length > 0
            ? montos.reduce((a, b) => a + b)
            : 0;

        return total;
    },
    getBudgetTotalExpenseEjecutado(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];

        if (budgetCategories.length == 0)
            return 0;
        let montos = budgetCategories
            .filter(c => typeof c.type == 'object' ? c.type?.code.toUpperCase() == CategoryType.Expense : c.type?.toUpperCase() == CategoryType.Expense)
            .map(c => c.subcategories)
            .flat()
            .map((a,b) => typeof a.executed == 'string' ? parseFloat(a.executed) * (+a.rate) : a.executed * (+a.rate) );

        var total = montos.length > 0
            ? montos.reduce((a, b) => a + b)
            : 0;

        return total;
    },
    getBudgetExecutedIncome(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        if (budgetCategories.length == 0)
            return 0;
        var montos = budgetCategories
            .filter(c => c.type == CategoryType.Income)
            .map(c => c.subcategories)
            .flat()
            .map((a,b) => typeof a.executed == 'string' ? parseFloat(a.executed) * (+a.rate) : a.executed * (+a.rate));
            
        var total = montos.length > 0
                    ? montos.reduce((a, b) => a + b)
                    : 0;
        return total;
    },
    getBudgetExecutedExpense(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        if (budgetCategories.length == 0)
            return 0;
        let montos = budgetCategories
            .filter(c => typeof c.type == 'object' ? c.type.code.toUpperCase() == CategoryType.Expense : c.type?.toUpperCase() == CategoryType.Expense)
            .map(c => c.subcategories)
            .flat()
            .map((a,b) => typeof a.executed == 'string' ? parseFloat(a.executed) * (+a.rate): a.executed * (+a.rate));

        var total = montos.length > 0
                    ? montos.reduce((a, b) => a + b)
                    : 0;
        return total;
    },
    getAllBudgetSubCategories(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        let subcategories = budgetCategories.flatMap(x => x.subcategories);
        return subcategories;
    },
    getBudgetExpenseSubCategories(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        let subcategories = budgetCategories
                            .filter(x => x.type == CategoryType.Expense)
                            .flatMap(x => x.subcategories);
        return subcategories;
    },
    getBudgetIncomeSubCategories(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        let subcategories = budgetCategories
                            .filter(x => x.type == CategoryType.Income)
                            .flatMap(x => x.subcategories);
        return subcategories;
    },
    getAllAvailableCategories(state: BudgetStore) {
        //This get all categories from Budget and catalogs
        let budgetCategories = state.activeBudget?.categories ?? [];
        let fullArray = [...budgetCategories, ...state.categoryCatalog];
        let flags: boolean[] = [];
        let distinctValues: BudgetCategory[] = []
        fullArray.forEach(category => {
            if (flags[category.uuid as any]) 
                return;
            flags[category.uuid as any] = true;
            distinctValues.push(category);
        })
        return distinctValues;
    },
    getBudgetCategoryCount(state: BudgetStore) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        return budgetCategories.length;
    },
    getBudgetTransactions(state: BudgetStore) {
        return state.budgetTransactions;
    },
    getBudgetConfiguration(state: BudgetStore) {
        return state.budgetConfiguration;
    },
    getShowDummyDataInChart(state: BudgetStore) {
        return state.showDummyDataInChart;
    },
    getEnrollmentCategoriesLoaded(state: BudgetStore) {
        return state.enrollmentCategoriesLoaded;
    },
    getIncomeTransactionAccounts(state: BudgetStore) {
        return state.userIncomeTransactionAccounts;
    },
    getExpenseTransactionAccounts(state: BudgetStore) {
        return [...state.userIncomeTransactionAccounts, ...state.userPaymentTransactionAccount]
    },
    getPaymentTransactionAccounts(state: BudgetStore) {
        return state.userPaymentTransactionAccount;
    },
    getSkipTutorial(state: BudgetStore) {
        return state.skipTutorial
    },
    getInvalidTransactionState(state: BudgetStore) {
        if (state.activeBudget) {
            const budgetDate = new Date(state.activeBudget?.date)
            const todayDate = new Date();

            return (budgetDate.getTime() - todayDate.getTime()) >= 0
        }
        return state.invalidTransactionState;
    },
    getServiceToPay(state: BudgetStore) {
        return state.serviceToPay;
    },
    getBudgetTypeTab(state: BudgetStore) {
        return state.budgetTypeTab;
    },
    getOlderBudget(state: BudgetStore) {
        if (state.activeBudget) {
            const sortedBudgets = state.userBudgets
                                .filter((ub: UserBudget) => ub.uuid !== state.activeBudget?.uuid)
                                .sort((a, b) => new Date(a.date).getTime() + new Date(b.date).getTime())
            for (let ub of sortedBudgets) {
                if (new Date(ub.date).getTime() < new Date(state.activeBudget?.date).getTime()) {
                    return ub;
                }
            }   
        }
        return ""
    },
    getNewerBudget(state: BudgetStore) {
        if (state.activeBudget) {
            const sortedBudgets = state.userBudgets
                                .filter((ub: UserBudget) => ub.uuid !== state.activeBudget?.uuid)
                                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            for (let ub of sortedBudgets) {
                if (new Date(ub.date).getTime() > new Date(state.activeBudget?.date).getTime()) {
                    return ub;
                }
            }   
        }
        return ""
    },
    getCategoriesByBudgetType(state: BudgetStore) {
        let filter = state.budgetTypeTab == 0 ? 'G' : 'I';
        return state.activeBudget?.categories.filter(c => typeof c.type == 'object' ? c.type.code.toUpperCase() == filter : c.type?.toUpperCase() == filter);
    },
    getLastVisitedTab(state: BudgetStore) {
        return state.lastVisitedTab
    },
    getLastVisitedSubTab(state: BudgetStore) {
        return state.lastVisitedSubTab
    },
    getLastUsedBudget(state: BudgetStore) {
        return state.lastBudgetUsed
    },
    get(state: BudgetStore) {
        return state.lastBudgetUsed
    }
}
// Mutations
const mutations = {
    SET_SHOW_NEW_CATEGORY_MODAL(state: BudgetStore, data: boolean) {
        state.showNewCategoryModal = data;
    },
    SET_USER_BUDGETS(state: BudgetStore, data: UserBudget[]) {
        state.userBudgets = data;
    },
    SET_ACTIVE_BUDGET(state: BudgetStore, data: UserBudget) {
        state.activeBudget = data;
    },
    SET_BUDGET_TEMPLATE(state: BudgetStore, data: BudgetTemplate[]) {
        state.budgetTemplates = data;
    },
    SET_LAST_ACTIVE_BUDGET(state: BudgetStore, budgetId: string) {
        state.lastBudgetUsed = budgetId;
    },
    SET_BUDGET_IS_LOADING(state: BudgetStore, data: boolean) {
        state.budgetIsLoading = data;
    },
    SET_BUDGET_CATEGORIES(state: BudgetStore, data: BudgetCategory[]) {
        if (state.activeBudget)
            state.activeBudget.categories = data;
        else
            console.error("could not found active budget")
    },
    SET_CATEGORY_CATALOG(state: BudgetStore, data: BudgetCategory[]) {
        state.categoryCatalog = data;
    },
    PUSH_NEW_CATEGORY(state: BudgetStore, data: BudgetCategory) {
        if (state.activeBudget) {
            state.activeBudget.categories.unshift(data)
        }
    },
    SET_CATEGORY_ALIAS(state: BudgetStore, data: BudgetCategory[]) {
        if (state.activeBudget?.categories) state.activeBudget.categories = data;
    },
    SET_CHANGE_ID(state: BudgetStore, data: string) {
        state.changeId = data
    },
    PUSH_NEW_SUB_CATEGORY(state: BudgetStore, data: { categoryIndex: number, category: BudgetCategory}) {
        let categories = state.activeBudget?.categories.slice()

        if (state.activeBudget && categories) {
            categories?.splice(data.categoryIndex, 1, data.category)
            state.activeBudget.categories = categories;
        }
    },
    SET_SUB_CATEGORY_NAME(state: BudgetStore, data: {
        categoryId: string,
        subCategory: BudgetSubCategory,
        name: string
    }) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        ensureCategoryIsInBudgetCategoriesStateData(data.categoryId, budgetCategories, state.categoryCatalog);
        let subCat = getOrSetSubCategory(budgetCategories, data.categoryId, data.subCategory);
        
        if (subCat)
            subCat.sub_category = data.name;
    },
    SET_SUB_CATEGORY_MONTO_PRESUPUESTADO(state: BudgetStore,
        data: {
            categoryId: string,
            subCategory: BudgetSubCategory,
            monto: number
        }) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        ensureCategoryIsInBudgetCategoriesStateData(data.categoryId, budgetCategories, state.categoryCatalog);
        let subCat = getOrSetSubCategory(budgetCategories, data.categoryId, data.subCategory);
        if (subCat)
            subCat.budgeted = data.monto;
        else
            console.warn("Subcat not found ", data.subCategory);
    },
    SET_SUB_CATEGORY_MONTO_EJECUTADO(state: BudgetStore,
        data: {
            userBudget: UserBudget,
            categoryId: string,
            subCategory: BudgetSubCategory,
            monto: number
        }) {
        let budgetCategories = data.userBudget?.categories ?? [];
        ensureCategoryIsInBudgetCategoriesStateData(data.categoryId, budgetCategories, state.categoryCatalog);
        let subCat = getOrSetSubCategory(budgetCategories, data.categoryId, data.subCategory);
        if (subCat) {
            subCat.executed = data.monto;
        }
    },
    SET_SHOW_ENROLLMENT_CATEGORIES_MODAL(state: BudgetStore, data: boolean) {
        state.showEnrollmentCategoriesModal = data;
    },
    SET_SHOW_PROFILE_MODAL(state: BudgetStore, data: boolean) {
        state.showProfileModal = data;
    },
    SET_SHOW_NEW_BUDGET_MODAL(state: BudgetStore, data: boolean) {
        state.showNewBudgetModal = data;
    },
    REMOVE_CATEGORY(state: BudgetStore, categoryId: string) {
        let categoryIndex = state.activeBudget?.categories.findIndex(x => x.uuid == categoryId);

        if (categoryIndex && categoryIndex > -1) {
            state.activeBudget?.categories.splice(categoryIndex, 1);
        }
    },
    REMOVE_SUB_CATEGORY(state: BudgetStore, data: {
        categoryId: string,
        subCategoryId: string
    }) {
        let category = state.activeBudget?.categories.find(x => x.uuid == data.categoryId);

        if (category) {
            category.subcategories = category?.subcategories.filter((sub: BudgetSubCategory) => sub.uuid !== data.subCategoryId)
        }
    },
    SET_CATEGORY_CATALOG_IS_LOADING(state: BudgetStore, data: boolean) {
        state.categoryCatalogIsLoading = data;
    },
    REPLACE_SUB_CATEGORY(state: BudgetStore, data: {
        categoryId: string,
        previousSubCategoryId: string,
        newSubCategoryId: string,
        newSubCategoryName: string
    }) {
        let budgetCategories = state.activeBudget?.categories ?? [];
        let subCat = getSubCategory(budgetCategories, data.categoryId, data.previousSubCategoryId) as BudgetSubCategory;
        subCat.uuid = data.newSubCategoryId;
        subCat.sub_category = data.newSubCategoryName;
    },
    SET_BUDGET_TRANSACTIONS(state: BudgetStore, data: BudgetTransaction[]) {
        state.budgetTransactions = data;
    },
    ADD_NEW_BUDGET_TRANSACTIONS(state: BudgetStore, data: BudgetTransaction) {
        let userBudgets = state.userBudgets;
        let targetUserBudget = getTargetBudgetOfTransaction(userBudgets, data);
        //Si el presupuesto de la transaccion es el mismo que esta activo, agregar al transaction list
        if (state.activeBudget?.date == targetUserBudget.date) {
            state.budgetTransactions.push(data);
        }
    },
    SET_BUDGET_CONFIGURATION(state: BudgetStore, data: BudgetConfiguration) {
        state.budgetConfiguration = data;
    },
    REMOVE_BUDGET_TRANSACTION(state: BudgetStore, transaction: BudgetTransaction) {
        var transactionIndex = state.budgetTransactions.indexOf(transaction);
        state.budgetTransactions.splice(transactionIndex, 1);
    },
    CHANGE_ACTIVE_BUDGET_CATEGORIES_ORDER(state: BudgetStore, categories: BudgetCategory[]) {
        if (state.activeBudget)
            state.activeBudget.categories = categories;
    },
    SET_SHOW_DUMMY_DATA_CHART(state: BudgetStore, value: boolean) {
        state.showDummyDataInChart = value;
    },
    SET_ENROLLMENT_CATEGORIES_LOADED(state: BudgetStore, value: boolean) {
        state.enrollmentCategoriesLoaded = value;
    },
    SET_SHOW_TRANSACTION_MODAL(state: BudgetStore, value: boolean) {
        state.resetTransactionForm = value;
    },
    SET_USER_INCOME_ACCOUNTS(state: BudgetStore, value: BankAccount[]) {
        state.userIncomeTransactionAccounts = value
    },
    SET_USER_EXPENSE_ACCOUNTS(state: BudgetStore, value: ExpenseTransactionAccount[]) {
        state.userExpenseTransactionAccounts = value;
    },
    SET_USER_PAYMENT_ACCOUNTS(state: BudgetStore, value: CreditCard[]) {
        state.userPaymentTransactionAccount = value;
    },
    SET_INITIAL_STATE(state: BudgetStore) {
        Object.assign(state, initialState());
    },
    SET_SKIP_TUTORIAL(state: BudgetStore, value: boolean) {
        state.skipTutorial = value;
    },
    SET_SERVICE_TO_PAY(state: BudgetStore, data: ServiceToPay) {
        state.serviceToPay = data
    },
    SET_BUDGET_TYPE_TAB(state: BudgetStore, tab: number) {
        state.budgetTypeTab = tab
    },
    SET_LAST_VISITED_TAB(state: BudgetStore, tab: number) {
        state.lastVisitedTab = tab
    },
    SET_LAST_VISITED_SUBTAB(state: BudgetStore, tab: number) {
        state.lastVisitedSubTab = tab
    }
}

// Actions 
const actions = {
    async getSkipTutorial(context: ActionContext<BudgetStore, BudgetStore>) {
        let cognitouser = await Auth.currentAuthenticatedUser();
        cognitouser.getUserAttributes((err:any, attributes: any) => {
            let skipTutorial = attributes?.find((x:any) => x.Name == "custom:skipTutorial")?.Value as Boolean
            skipTutorial = skipTutorial ? skipTutorial : false;
            context.commit("SET_SKIP_TUTORIAL", skipTutorial);
        });
    },
    async setSkipTutorial(context: ActionContext<BudgetStore, BudgetStore>) {
        let cognitouser = await Auth.currentAuthenticatedUser();
        Auth.updateUserAttributes(cognitouser, {'custom:skipTutorial': 'true'});
        context.commit("SET_SKIP_TUTORIAL", true);
    },
    showNewCategoryModal(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_NEW_CATEGORY_MODAL", true);
    },
    hideNewCategoryModal(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_NEW_CATEGORY_MODAL", false);
    },
    budgetIsLoading(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_BUDGET_IS_LOADING", true);
    },
    loadUserBudgets(context: ActionContext<BudgetStore, BudgetStore>, payload: UserBudget[]) {
        context.commit("SET_BUDGET_IS_LOADING", true);
        budgetService.GetUserBudgets()
            .then((res: any) => {
                context.commit("SET_USER_BUDGETS", res.data);
                if (res.data && res.data.length > 0) {
                    let activeBudgetId = res.data[0].uuid;

                    let currentYearMonth = new Date().toISOString();
                    let currentMonthBudget = res.data.find((b: UserBudget) => b.date.substring(0, 7) == currentYearMonth.substring(0, 7));
                    if (currentMonthBudget) activeBudgetId = currentMonthBudget.uuid

                    context.dispatch("setActiveBudget", activeBudgetId); 
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                context.commit("SET_BUDGET_IS_LOADING", false);
            })
        // context.commit("SET_USER_BUDGETS", payload);
        // // context.commit("SET_BUDGET_CATEGORIES", principalBudget.categorias);
        // context.commit("SET_BUDGET_IS_LOADING", false);
        // context.dispatch("emitPresupuestoChange");
        context.dispatch("loadCategoryCatalog");
        context.dispatch("getBudgetTemplates");
    },
    async loadCategoryCatalog(context: ActionContext<BudgetStore, BudgetStore>) {
        return;
        let response = await commonService.GetCategories();

        if (response.status == 200) {
            context.commit("SET_CATEGORY_CATALOG", response.data)
        }
    },
    budgetLoaded(context: ActionContext<BudgetStore, BudgetStore>, payload: UserBudget[]) {
        context.commit("SET_USER_BUDGETS", payload);
        // context.commit("SET_BUDGET_CATEGORIES", principalBudget.categorias);
        context.commit("SET_BUDGET_IS_LOADING", false);
        context.dispatch("emitPresupuestoChange");
        // context.dispatch("setActiveBudget", payload);
    },
    setActiveBudget(context: ActionContext<BudgetStore, BudgetStore>, budgetId: string) {
        context.commit("SET_BUDGET_IS_LOADING", true);
        
        budgetService.RetrieveUserBudget(budgetId)
            .then((res: any) => {
                const activeBudget = {
                    ...res.data,
                    categories: res.data.budget_categories
                }
                context.commit("SET_ACTIVE_BUDGET", activeBudget);
                context.dispatch("getBudgetTransactions");
                context.dispatch("emitPresupuestoChange");
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                context.commit("SET_BUDGET_IS_LOADING", false);
            })
    },
    changeActiveBudget(context: ActionContext<BudgetStore, BudgetStore>, payload: UserBudget) {
        context.commit("SET_BUDGET_IS_LOADING", true);
        context.commit("SET_ACTIVE_BUDGET", payload);
        context.commit("SET_LAST_ACTIVE_BUDGET", payload.uuid);
        // context.commit("SET_BUDGET_CATEGORIES", payload.categorias);
        context.dispatch("emitPresupuestoChange");
        context.commit("SET_BUDGET_IS_LOADING", false);
    },
    categoryCatalogLoaded(context: ActionContext<BudgetStore, BudgetStore>, payload: BudgetCategory[]) {
        // context.commit("SET_CATEGORY_CATALOG", payload);
        // context.commit("SET_CATEGORY_CATALOG_IS_LOADING", false);
    },
    async addNewCategory(context: ActionContext<BudgetStore, BudgetStore>, payload: BudgetCategory) {
        payload.budget = context.getters.getActiveBudget.uuid;

        try {
            let response = await budgetService.CreateBudgetCategory(payload)

            if (response.status == 201) {
                let subcategoriesFromCatalog = context.getters.getCategoryCatalog.find((c: BudgetCategory) => c.uuid == payload.category);
                let subcategoriesToAdd = []
                if (subcategoriesFromCatalog) {
                    for (let [index, value] of subcategoriesFromCatalog.subcategories.entries()) {
                        let data = {
                            "uuid": index,
                            "alias": value.name,
                            "executed": 0,
                            "budgeted": 0,
                            "category": (response.data as any).uuid,
                            "subscription": payload.subscription,
                            "rate": 1
                        }
                        subcategoriesToAdd.push(data)
                    }
                    let updatedCategory: any = {
                        ...response.data,
                        subcategories: subcategoriesToAdd
                    }
                    context.commit('PUSH_NEW_CATEGORY', updatedCategory);
                }  
                
            }
        } catch (error) {
            console.log(error)   
        }
    },
    async removeCategory(context: ActionContext<BudgetStore, BudgetStore>, categoryId: string) {
        try {
            let response = await budgetService.DeleteBudgetCategory(categoryId)
            if (response.status == 204) {
                context.dispatch("setActiveBudget", context.getters.getActiveBudget.uuid); 
            }
        } catch (error) {
            console.log(error)
        }
        // context.commit("REMOVE_CATEGORY", categoryId);
    },
    async changeCategoryAlias(context: ActionContext<BudgetStore, BudgetStore>, payload: BudgetCategory) {
        let response = await budgetService.EditBudgetCategory({ uuid: payload.uuid, alias: payload.alias })

        if (response.status === 200) {
            let updatedCategories = context.getters.getActiveBudget.categories.slice();
            let categoryToEdit = context.getters.getActiveBudget.categories.find((cat: BudgetCategory) => cat.uuid == payload.uuid);
            let categoryToEditIndex = context.getters.getActiveBudget.categories.findIndex((cat: BudgetCategory) => cat.uuid == payload.uuid);
            let updatedCategory: BudgetCategory = {
                ...categoryToEdit,
                alias: payload.alias
            }
            updatedCategories.splice(categoryToEditIndex, 1, updatedCategory);
            context.commit("SET_CATEGORY_ALIAS", updatedCategories);
        }
    },
    emitPresupuestoChange(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_CHANGE_ID", generateGUID());
    },
    async addNewSubCategory(context: ActionContext<BudgetStore, BudgetStore>, data: {payload: BudgetSubCategory, index? : number}) {
        try {
            let response = await budgetService.CreateBudgetSubCategory(data.payload)

            if (response.status == 201) {
                let categoryIndex = context.getters.getActiveBudget.categories.findIndex((c: BudgetCategory) => c.uuid == data.payload.category)
                let currentCategory = context.getters.getActiveBudget.categories.find((c: BudgetCategory) => c.uuid == data.payload.category)
                let updatedSubcategories = currentCategory.subcategories.slice();

                if (data.index || data.index == 0) {
                    let subcategoryIndex = updatedSubcategories.findIndex((sc: BudgetSubCategory) => sc.uuid == data.index?.toString());
                    updatedSubcategories.splice(subcategoryIndex, 1, response.data)
                } else {
                    updatedSubcategories.push(response.data);
                }
                
                let updatedCategory: BudgetCategory = {
                    ...currentCategory,
                    subcategories: updatedSubcategories
                }
                context.commit("PUSH_NEW_SUB_CATEGORY", { categoryIndex: categoryIndex, category: updatedCategory})
                return { result: 'success' }
            }
        } catch (error) {
            console.log(error)
            return { result: 'error' }
        }
    },
    async removeSubCategory(context: ActionContext<BudgetStore, BudgetStore>, payload: BudgetSubCategory) {
        if (isNaN(parseFloat(payload.uuid)) || isNaN(+payload.uuid)) {
            try {
                let response = await budgetService.DeleteBudgetSubCategory(payload.uuid)

                if (response.status == 204) {
                    let categoryIndex = context.getters.getActiveBudget.categories.findIndex((c: BudgetCategory) => c.uuid == payload.category)
                    let currentCategory = context.getters.getActiveBudget.categories.find((c: BudgetCategory) => c.uuid == payload.category)
                    let updatedSubcategories = currentCategory.subcategories.filter((sc: BudgetSubCategory) => sc.uuid !== payload.uuid)
                    let updatedCategory: BudgetCategory = {
                        ...currentCategory,
                        subcategories: updatedSubcategories
                    }
                    context.commit("PUSH_NEW_SUB_CATEGORY", { categoryIndex: categoryIndex, category: updatedCategory})
                }
            } catch (e) {
                console.log(e)
            }
        } else {
            let categoryIndex = context.getters.getActiveBudget.categories.findIndex((c: BudgetCategory) => c.uuid == payload.category)
            let currentCategory = context.getters.getActiveBudget.categories.find((c: BudgetCategory) => c.uuid == payload.category)
            let updatedSubcategories = currentCategory.subcategories.filter((sc: BudgetSubCategory) => sc.uuid !== payload.uuid)
            let updatedCategory: BudgetCategory = {
                ...currentCategory,
                subcategories: updatedSubcategories
            }
            context.commit("PUSH_NEW_SUB_CATEGORY", { categoryIndex: categoryIndex, category: updatedCategory})
        }
    },
    async changeSubCategoryMontoPresupuestado(context: ActionContext<BudgetStore, BudgetStore>, subCategory: BudgetSubCategoryEdit) {
        let categoryIndex = context.getters.getActiveBudget.categories.findIndex((c: BudgetCategory) => c.uuid == subCategory.categoryId)
        let currentCategory = context.getters.getActiveBudget.categories[categoryIndex];
        let subcategoriesCopy = currentCategory.subcategories.slice()
        let subCategoryIndex = subcategoriesCopy.findIndex((sc: BudgetSubCategory) => sc.uuid == subCategory.uuid)
        subcategoriesCopy.splice(subCategoryIndex, 1, subCategory);

        let updatedCategory: BudgetCategory = {
            ...currentCategory,
            subcategories: subcategoriesCopy
        }
        context.commit("PUSH_NEW_SUB_CATEGORY", { categoryIndex: categoryIndex, category: updatedCategory})
        context.dispatch("emitPresupuestoChange");

        const subcategoryToEdit = { subscription: '', uuid: subCategory.uuid, budgeted: subCategory.budgeted, alias: subCategory.alias, categoryId: subCategory.categoryId}

        try {
            let response = await budgetService.EditBudgetSubCategory(subcategoryToEdit)
            if (response.status == 200) return { result: 'success' }
        } catch (error) {
            context.commit("PUSH_NEW_SUB_CATEGORY", { categoryIndex: categoryIndex, category: currentCategory})
            context.dispatch("emitPresupuestoChange");
            return { result: 'error' }
        }
    },
    changeSubCategoryName(context: ActionContext<BudgetStore, BudgetStore>, payload: {
        categoryId: string,
        subCategory: BudgetSubCategory,
        name: string
    }) {
        context.commit("SET_SUB_CATEGORY_NAME", payload);
    },
    replaceSubCategory(context: ActionContext<BudgetStore, BudgetStore>, payload: {
        userId: string
        categoryId: string,
        previousSubCategoryId: string,
        newSubCategoryId: string,
        newSubCategoryName: string
    }) {
        //Here we have to first do the replacement
        //Then call for previesSubCategory for deletion on Database
        let category = context.state.activeBudget?.categories
                        .find((c) => c.uuid == payload.categoryId);
        if (category) {
            let budgetCategories = context.state.activeBudget?.categories ?? [];
            let subCat = getSubCategory(budgetCategories, payload.categoryId, payload.previousSubCategoryId) as BudgetSubCategory;
            let subCatToDelete = { ...subCat } as BudgetSubCategory;
            context.commit("REPLACE_SUB_CATEGORY", payload)
            // context.commit("PUSH_NEW_SUB_CATEGORY", { 
            //                                     categoryId: payload.categoryId,
            //                                     subCategory: newSubCat,
            //                                     index: previousSubCatIndex });
            //
            let budgetDate = (context.getters.getActiveBudget as UserBudget).date;
            return budgetService
                .DeleteSubCategory(payload.userId, budgetDate, category, subCatToDelete)
        } else {
            console.warn("Could not find category when triying to replace ", payload.categoryId)
        }
        
    },
    // changeSubCategoryMontoEjecutado(context: ActionContext<BudgetStore, BudgetStore>,
    //     payload: {
    //         categoryId: string,
    //         subCategoryId: string,
    //         monto: number
    //     }) {
    //     context.commit("SET_SUB_CATEGORY_MONTO_EJECUTADO", payload);
    // },
    showEnrollmentCategoriesModal(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_ENROLLMENT_CATEGORIES_MODAL", true);
    },
    hideEnrollmentCategoriesModal(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_ENROLLMENT_CATEGORIES_MODAL", false);
    },
    showProfileModal(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_PROFILE_MODAL", true);
    },
    hideProfileModal(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_PROFILE_MODAL", false);
    },
    startAddTransactionAnimation(context: ActionContext<BudgetStore, BudgetStore>) {
        //This only serve as event to suscribe to, in other components
    },
    stopAddTransactionAnimation(context: ActionContext<BudgetStore, BudgetStore>) {
        //This only serve as event to suscribe to, in other components
    },
    budgetTransactionsLoaded(context: ActionContext<BudgetStore, BudgetStore>, payload: BudgetTransaction[]) {
        context.commit("SET_BUDGET_TRANSACTIONS", payload)
    },
    async newTransactionAdded(context: ActionContext<BudgetStore, BudgetStore>, payload: BudgetTransaction) {
        let activeBudgetId = context.getters.getActiveBudget.uuid;
        context.dispatch("setActiveBudget", activeBudgetId); 
        // try {
        //     let response = await budgetService.CreateTransactions(payload);
        //     if (response.status == 200) {
        //         // Add to transaction list
        //     }
        // } catch (error) {
        //     console.log(error)
        // }
    },
    async getBudgetTransactions(context: ActionContext<BudgetStore, BudgetStore>) {
        try {
            let response = await budgetService.GetTransactions();

            if (response.status == 200) {
                context.commit("SET_BUDGET_TRANSACTIONS", response.data)
            }
        } catch (error) {
            console.log(error)
        }
    },
    budgetConfigurationLoaded(context: ActionContext<BudgetStore, BudgetStore>, payload: BudgetConfiguration) {
        context.commit("SET_BUDGET_CONFIGURATION", payload)
    },
    showNewBudgetModal(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_NEW_BUDGET_MODAL", true);
    },
    hideNewBudgetModal(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_NEW_BUDGET_MODAL", false);
    },
    activateTutorial(context: ActionContext<BudgetStore, BudgetStore>) {
        //This only serve as event to suscribe to, in other components
    },
    async removeTransaction(context: ActionContext<BudgetStore, BudgetStore>, transactionId: string) {
        let activeBudgetId = context.getters.getActiveBudget.uuid;

        try {
            let response = await budgetService.DeleteTransactions(transactionId)
            if (response && response.status === 204) {
                let transactions = context.getters.getBudgetTransactions.filter((t: BudgetTransaction) => t.uuid !== transactionId)
                context.commit('SET_BUDGET_TRANSACTIONS', transactions)
                context.dispatch("Dashboard/setGlobalTransactions", {}, { root: true })
                context.dispatch("Dashboard/setDashboardTransactions", {}, { root: true })
                context.dispatch("setActiveBudget", activeBudgetId); 
            }
        } catch (error) {
            console.log(error)
        }
    },
    changeBudgetCategoriesOrder(context: ActionContext<BudgetStore, BudgetStore>, payload: BudgetCategory[]) {
        context.commit("CHANGE_ACTIVE_BUDGET_CATEGORIES_ORDER", payload);
    },
    setDummyDataToChartSummary(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_DUMMY_DATA_CHART", true);
    },
    removeDummyDataToChartSummary(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_SHOW_DUMMY_DATA_CHART", false);
    },
    enrollmentCategoriesLoaded(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_ENROLLMENT_CATEGORIES_LOADED", true);
    },
    async deleteActiveBudget(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit("SET_BUDGET_IS_LOADING", true);
        const activeBudgetId = context.state.activeBudget ? context.state.activeBudget?.uuid : "";
        try {
            let response = await budgetService.DeleteUserBudget(activeBudgetId);
            if (response.status == 204) {
                let currentBudgets = context.getters.getUserBudgets.filter((b: UserBudget) => b.uuid !== activeBudgetId)

                if (currentBudgets.length > 0) context.dispatch("setActiveBudget", currentBudgets[0].uuid);
                context.commit("SET_USER_BUDGETS", currentBudgets)
            }
        } catch (error) {
            console.log(error)            
        }
        context.commit("SET_BUDGET_IS_LOADING", false);
        // const budgetDate = context.state.activeBudget?.date;

        // if (budgetDate) {
        //     budgetService.DeleteBudget(userId, budgetDate)
        //         .then(res => {
        //             let updatedBudgetList = context.state.userBudgets.filter(budget => budget.date !== budgetDate);
        //             if (updatedBudgetList.length) {
        //                 updatedBudgetList = updatedBudgetList.sort((budgetA, budgetB) => new Date(budgetB.date).getTime() - new Date(budgetA.date).getTime())
        //                 context.commit("SET_USER_BUDGETS", updatedBudgetList);
        //                 context.commit("SET_ACTIVE_BUDGET", updatedBudgetList[0]);
        //             } else {
        //                 context.commit("SET_USER_BUDGETS", []);
        //                 context.commit("SET_ACTIVE_BUDGET", null);
        //                 context.commit("SET_BUDGET_TRANSACTIONS", []);
        //             }
        //             // context.commit("SET_USER_BUDGETS", updatedBudgetList)
        //             // context.commit("SET_ACTIVE_BUDGET", updatedBudgetList[0]);
        //             context.dispatch("emitPresupuestoChange");
        //             context.commit("SET_BUDGET_IS_LOADING", false);
        //         })
        //         .catch(err => {
        //             console.warn("Error deleting current budget.")
        //         })
        //         .finally(() => {
        //             context.commit("SET_BUDGET_IS_LOADING", false);
        //         })
        // }
    },
    getBudgetConfig(context: ActionContext<BudgetStore, BudgetStore>, userId: string) {
        return;
        budgetService.GetConfigurations(userId)
            .then(res => {
                context.commit('SET_BUDGET_CONFIGURATION', res.data.entity)
                context.dispatch("getUserBudget", userId);
            })    
    },
    async getBudgetTemplates(context: ActionContext<BudgetStore, BudgetStore>, userId: string) {
        try {
            let response = await idService.UserBudgetTemplates();

            if (response.status == 200) {
                context.commit('SET_BUDGET_TEMPLATE', response.data)
                let budgetTemplate = (response.data as any)[0]
                let cats = budgetTemplate.btcategories.map((btc: BudgetTemplateCategory) => {
                    let subcats = btc.btcsubcategories.map((btcs: BudgetTemplateSubCategory) => btcs.sub_category)
                    return {
                        ...btc.category,
                        subcategories: subcats
                    }
                })
                context.commit('SET_CATEGORY_CATALOG', cats);
                
            }
        } catch (error) {
            console.log(error)
        }
    },
    getUserBudget(context: ActionContext<BudgetStore, BudgetStore>, userId: string) {
        // budgetService.GetUserBudgets()
        //     .then(res => {
        //         const budgets = res.data.entity;
        //         if (budgets.length) {
        //             context.commit("SET_USER_BUDGETS", budgets);
        //             context.dispatch("emitPresupuestoChange");
        //             context.dispatch("setActiveBudget", budgets);
        //         }
        //         context.commit("SET_BUDGET_IS_LOADING", false);
        //     }).catch(err => {
        //         context.commit("SET_BUDGET_IS_LOADING", false);
        //     })
    },
    toggleTransactionModelResetState(context: ActionContext<BudgetStore, BudgetStore>, value: boolean) {
        context.commit('SET_SHOW_TRANSACTION_MODAL', value);
    },
    setIncomeAccounts(context: ActionContext<BudgetStore, BudgetStore>, incomeAccounts: BankAccount[]) {
        // context.commit('SET_USER_INCOME_ACCOUNTS', incomeAccounts);
    },
    setPaymentAccounts(context: ActionContext<BudgetStore, BudgetStore>, paymentAccounts: CreditCard[]) {
        // context.commit('SET_USER_PAYMENT_ACCOUNTS', paymentAccounts);
    },
    clearData(context: ActionContext<BudgetStore, BudgetStore>) {
        context.commit('SET_INITIAL_STATE')
    },
    setServiceToPay(context: ActionContext<BudgetStore, BudgetStore>, data: ServiceToPay) {
        context.commit('SET_SERVICE_TO_PAY', data);
    },
    setBudgetTypeTab(context: ActionContext<BudgetStore, BudgetStore>, tab: number) {
        context.commit("SET_BUDGET_TYPE_TAB", tab)
    },
    async setTransactionAccount(context: ActionContext<BudgetStore, BudgetStore>, type: string) {
        try {
            let response = await catalogService.GetAccountsForTransactions(type)
            if (response.status == 200) {
                if (type.toLowerCase() == 'i') context.commit('SET_USER_INCOME_ACCOUNTS', response.data);
                else if (type.toLowerCase() == 'g') context.commit('SET_USER_EXPENSE_ACCOUNTS', response.data);
                else context.commit('SET_USER_PAYMENT_ACCOUNTS', response.data);
            }
        } catch (error) {
            console.log(error)
        }
    },
    async getTransactionAccounts(context: ActionContext<BudgetStore, BudgetStore>, type: string) {
        let transactionTypes = ['i', 'g', 'p'];
        transactionTypes.forEach(t => context.dispatch('setTransactionAccount', t))
    },
}

// function filterExpenseAccounts(data: any) {
//     const creditCardAccounts = data.filter((i:any) => i['codigo'] === 'tc') as CreditCard[];
//     const bankAccounts = data.filter((i:any) => i['codigo'] === 'cb') as BankAccount[];

//     let ccExpenseAccounts = creditCardAccounts.map(cc => {
//         console.log(cc);
//         let dopAccount = cc.saldos.find((saldo: any) => saldo.moneda.codigo.toLowerCase() === 'dop');
//         let usdAccount = cc.saldos.find((saldo: any) => saldo.moneda.codigo.toLowerCase() === 'usd');

//         return {
//             id: cc.uuid,
//             numero: cc.numero,
//             moneda: cc.saldos.length > 1 ? 'Doble saldo' : cc.saldos[0].moneda.code,
//             banco: cc.banco.short_name,
//             balanceDOP: dopAccount ? dopAccount.limite - dopAccount.consumido : 0,
//             balanceUSD: usdAccount ? usdAccount.limite - usdAccount.consumido : 0,
//             tipo: 'tc'
//         } as ExpenseTransactionAccount
//     }) 
//     let bankExpenseAccounts = bankAccounts.map(ba => {
//         return {
//             id: ba.uuid,
//             numero: ba.account_no,
//             moneda: ba.currency,
//             banco: ba.bank,
//             balanceDOP: ba.balance,
//             balanceUSD: ba.balance,
//             descripcion: ba.sub_type,
//             tipo: 'cb'
//         } as ExpenseTransactionAccount
//     }) 

//     return [...ccExpenseAccounts, ...bankExpenseAccounts]
// }

function getOrSetSubCategory(categories: BudgetCategory[], categoryId: string, subCategory: BudgetSubCategory) {
    let category = categories
        .find((c) => c.uuid == categoryId);
    if (!category)
        console.warn("category: " + categoryId + " Not found")
    //
    let subCat = category?.subcategories?.find((sc: BudgetSubCategory) => sc.uuid == subCategory.uuid);
    if (!subCat && category) {
        //If we dont found a subcategory, it means is a custom subCategory
        //For that reason we have to use the object we recieve and add it to the category
        subCat = subCategory;
        category.subcategories?.push(subCat);
    }
    return subCat;
}

function getSubCategory(categories: BudgetCategory[], categoryId: string, subCategoryId: string) {
    let category = categories
        .find((c) => c.uuid == categoryId);
    if (!category)
        console.warn("category: " + categoryId + " Not found")
    //
    let subCat = category?.subcategories?.find((sc: BudgetSubCategory) => sc.uuid == subCategoryId);
    return subCat;
}

function ensureCategoryIsInBudgetCategoriesStateData(categoryId: string,
    budgetCategories: BudgetCategory[],
    categoryCatalog: BudgetCategory[]) {
    let cat = budgetCategories.find(c => c.uuid == categoryId);
    if (cat && cat.subcategories)
    {
        //Make copy to not modify BudgetCatalog Categories
        cat.subcategories = cat
                            .subcategories
                            .map((x: BudgetSubCategory) => { 
                                return {...x } 
                            });
        return;
    }
        
    cat = categoryCatalog.find(c => c.uuid == categoryId);

    if (cat && cat.subcategories) {
        //Make copy to not modify BudgetCatalog Categories
        cat.subcategories = cat
                            .subcategories
                            .map((x: BudgetSubCategory) => { 
                                return {...x } 
                            });
        budgetCategories.push(cat);
    } else {
        console.warn("Trying to add unkown categoryId: "+ categoryId);
    }
}

function getTargetBudgetOfTransaction(userBudgets: UserBudget[], transaction: BudgetTransaction) {
    let targetUserBudget = userBudgets.filter(u => {
        let budgetDate = new Date(u.date);
        let transactionDate = new Date(transaction.date);
        //Si el mes y el año coincide es el user budget a modificar
        return budgetDate.getUTCMonth() == transactionDate.getUTCMonth()
            && budgetDate.getUTCFullYear() == transactionDate.getUTCFullYear();
    })[0];

    return targetUserBudget;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}