import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Vue from 'vue';
import router from '@/router';
import AppSettings from '@/AppSettings';

Sentry.init({
  Vue,
  dsn: AppSettings.SENTRY_DNS,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["*"],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true
});