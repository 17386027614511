
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Bank, AccountType } from '@/models/Catalog/Catalog'
import { CreditCard, CreditCardBalance, ServiceToPay } from '@/models/UserCatalog/UserCatalog'
import { Currency, TransactionRedirection } from '@/models/Common/Common'
import UserModel from "@/models/IdentityManagement/UserModel";
import _ from "lodash";
import UIkit from 'uikit';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { CurrencyCode } from "@/components/assetsLiabilities/mixins";
import { CurrencyInput, setValue }  from 'vue-currency-input';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    CurrencyInput
  }
})

export default class AddCreditCardModal extends Mixins(CurrencyCode) {
  doubleBalance = false;
  saldoPrincipal = { used: 0.00, limit: 0.00, currency: '' } as CreditCardBalance;
  saldoSecundario = { used: 0.00, limit: 0.00, currency: '' } as CreditCardBalance;
  currentCreditCard = {} as CreditCard;
  @Getter("Asset/getBanks") bankList!: Bank[];
  @Getter("Asset/getUserCreditCards") creditCards!: CreditCard[]
  @Getter("Asset/getCurrencies") currencies!: Currency[];
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Dashboard/getProductModalCalledFromDashboard") calledFromDashboard!: TransactionRedirection;
  @Getter("Budget/getServiceToPay") serviceToPay!: ServiceToPay;
  localServiceToPay = {} as ServiceToPay
  showCurrencies = false;
  isEditing = false;
  money = {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false
  }
  showErrors = false;
  emptyCurrencyState = {} as Currency;

  get filteredCurrencyForDoubleBalance() {
    if (this.saldoPrincipal.currency) {
      return this.currencies.filter(currency => currency.uuid != this.saldoPrincipal.currency)
    } 
    return this.currencies;
  }

  @Watch("serviceToPay")
  storeServiceToPay(value: ServiceToPay) {
    if (value) this.localServiceToPay = { ...value }
  }

  get cardAlreadyExists() {
    if (this.currentCreditCard && this.currentCreditCard.account_no && this.currentCreditCard.expiration_date && !this.isEditing) {
      return this.creditCards.filter(cc => cc.account_no == this.currentCreditCard.account_no && cc.expiration_date == this.currentCreditCard.expiration_date).length > 0
    }
    return false
  }  

  get mainBalanceCurrency() {
    if (this.saldoPrincipal && this.saldoPrincipal.currency) return this.currencies.find(c => c.uuid == this.saldoPrincipal.currency)?.code
    return ''
  }

  get secondBalanceCurrency() {
    if (this.saldoSecundario && this.saldoSecundario.currency) return this.currencies.find(c => c.uuid == this.saldoSecundario.currency)?.code
    return ''
  }

  @Watch("doubleBalance")
  setBalanceCurrencies(value: Boolean) {
    if (!this.isEditing) {
      this.saldoPrincipal = {
        currency: this.currencies.filter(currency => currency.code.toLowerCase() === 'dop')[0].uuid,
        limit: 0,
        used: 0
      } as CreditCardBalance
      
      this.saldoSecundario = {
        currency: this.currencies.filter(currency => currency.code.toLowerCase() === 'usd')[0].uuid,
        limit: 0,
        used: 0
      } as CreditCardBalance
    }
  } 

  @Watch("currencies")
  toggleCurrency() {
    this.showCurrencies = true;
  }

  closeModal() {
    UIkit.modal(document.getElementById('credit-card-modal')).hide();
  }

  openModal() {
    UIkit.modal(document.getElementById('credit-card-modal')).show()
  }

  created() {
    this.$root.$on('clearCreditCard', () => {
      this.clearCreditCard()
    });
    this.$root.$on('editCreditCard', (editingCreditCard: CreditCard) => {
      this.editCreditCard(editingCreditCard);
    });
  }

  async validateForm(saveAndContinue=false) {
    const isFormValid = await (this.$refs['cc-form'] as any).validate()

    if (!isFormValid) {
      this.showErrors = true;
      return;
    }

    if (isFormValid && !saveAndContinue && !this.cardAlreadyExists) {
      this.saveCreditCard();
    }

    if (isFormValid && saveAndContinue && !this.cardAlreadyExists) {
      this.saveAndContinue()
    }
  }

  saveAndContinue() {
    this.currentCreditCard.multi_currency = this.doubleBalance;
    this.saldoPrincipal = {
      ...this.saldoPrincipal,
      used: this.saldoPrincipal.updated_used
    };
    this.saldoSecundario = {
      ...this.saldoSecundario,
      used: this.saldoSecundario.updated_used
    };
    this.$store.dispatch("Asset/saveCreditCard", { 
      payload: this.currentCreditCard, 
      isEditing: this.isEditing, 
      userId: this.user.id,
      saldoPrincipal: _.cloneDeep(this.saldoPrincipal),
      saldoSecundario: this.saldoSecundario,
      isDoubleBalance : this.doubleBalance
    });
    this.closeModal();
    this.clearCreditCard();
    setTimeout(this.openModal, 1000);
    this.$store.dispatch("Dashboard/setPatrimony", {userId: this.user.id, moneda: this.defaultCurrencyCode});
  }


  saveCreditCard() {
    this.currentCreditCard.multi_currency = this.doubleBalance; 
    this.saldoPrincipal = {
      ...this.saldoPrincipal,
      used: this.saldoPrincipal.updated_used
    };
    this.saldoSecundario = {
      ...this.saldoSecundario,
      used: this.saldoSecundario.updated_used
    };
    this.$store.dispatch("Asset/saveCreditCard", { 
      payload: this.currentCreditCard, 
      isEditing: this.isEditing, 
      userId: this.user.id,
      saldoPrincipal: _.cloneDeep(this.saldoPrincipal),
      saldoSecundario: this.saldoSecundario,
      isDoubleBalance : this.doubleBalance
    })
    this.clearCreditCard();
    this.closeModal();
    this.$store.dispatch("Dashboard/setPatrimony", {userId: this.user.id, moneda: this.defaultCurrencyCode});
    this.$store.dispatch("Budget/getTransactionAccounts");
    if (this.calledFromDashboard.redirect) {
      this.openTransactionModal(this.calledFromDashboard.to);
    }
  }

  openTransactionModal(to: string) {
    this.$store.dispatch("Dashboard/setProductCallFromDashboard", { redirect: false, to: '' } as TransactionRedirection);
    this.$store.dispatch('Budget/setServiceToPay', this.localServiceToPay);
    this.localServiceToPay = {} as ServiceToPay;
    
    ($('#transactionModal') as any).modal();
    const el = document.getElementById(to);
    el?.click();
  }

  clearCreditCard() {
    this.currentCreditCard = {} as CreditCard;
    this.saldoPrincipal = { used: 0.00, limit: 0.00, currency: ''} as CreditCardBalance;
    this.saldoSecundario = { used: 0.00, limit: 0.00, currency: ''} as CreditCardBalance;
    this.isEditing = false;
    this.doubleBalance = false;
    this.resetFormValidation();
  }

  resetFormValidation() {
     (this.$refs['cc-form'] as any).reset()
     this.showErrors = false;
  } 

  editCreditCard(editingCreditCard: CreditCard) {
    this.clearCreditCard();
    const ccToBeEdited = _.cloneDeep(editingCreditCard)
    this.currentCreditCard = ccToBeEdited;
    this.isEditing = true;
    this.doubleBalance = ccToBeEdited.multi_currency;
    this.saldoPrincipal = {
      ...ccToBeEdited.balances[0],
      used: typeof ccToBeEdited.balances[0].updated_used == "number" ? ccToBeEdited.balances[0].updated_used : parseFloat(ccToBeEdited.balances[0].updated_used),
      limit: typeof ccToBeEdited.balances[0].limit == "number" ? ccToBeEdited.balances[0].limit : parseFloat(ccToBeEdited.balances[0].limit),
      currency: typeof ccToBeEdited.balances[0].currency == "object" ? ccToBeEdited.balances[0].currency.uuid : ccToBeEdited.balances[0].currency
    }
    if (this.doubleBalance) {
      this.saldoSecundario = {
        ...ccToBeEdited.balances[1],
        used: typeof ccToBeEdited.balances[1].updated_used == "number" ? ccToBeEdited.balances[0].updated_used : parseFloat(ccToBeEdited.balances[1].updated_used),
        limit: typeof ccToBeEdited.balances[1].limit == "number" ? ccToBeEdited.balances[0].limit : parseFloat(ccToBeEdited.balances[1].limit),
        currency: typeof ccToBeEdited.balances[1].currency == "object" ? ccToBeEdited.balances[1].currency.uuid : ccToBeEdited.balances[1].currency
      }
    }
  }
}
