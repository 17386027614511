
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Bank, AccountType, AccountTypeSubList } from '@/models/Catalog/Catalog'
import { BankAccount, AccountType as UserAccountType, ServiceToPay } from '@/models/UserCatalog/UserCatalog'
import { Currency, TransactionRedirection } from '@/models/Common/Common'
import UserModel from "@/models/IdentityManagement/UserModel";
import { ModalHelper, CurrencyCode } from "@/components/assetsLiabilities/mixins";
import UIkit from 'uikit';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { CurrencyInput, setValue }  from 'vue-currency-input';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    CurrencyInput
  }
})
export default class AddBankAccountModal extends Mixins(ModalHelper, CurrencyCode) {
  currentBankAccount = { balance: 0 } as BankAccount
  @Getter("Asset/getBanks") bankList!: Bank[];
  @Getter("Asset/getBankSubTypes") bankAccountTypes!: AccountTypeSubList[];
  @Getter("Asset/getAccountTypes") accountTypes!: AccountType[];
  @Getter("Asset/getCurrencies") currencies!: Currency[];
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter('Asset/getUserBankAccounts') bankAccounts! : BankAccount[];
  @Getter("Dashboard/getProductModalCalledFromDashboard") calledFromDashboard!: TransactionRedirection;
  @Getter("Budget/getServiceToPay") serviceToPay!: ServiceToPay;
  localServiceToPay = {} as ServiceToPay

  isEditing = false;
  showErrors = false;

  get invalidAmount() {
    // return !this.currentBankAccount.balanceActual || this.currentBankAccount.balanceActual < 0
    return !this.currentBankAccount.balance || this.currentBankAccount.balance < 0
  }

  @Watch("serviceToPay")
  storeServiceToPay(value: ServiceToPay) {
    if (value) this.localServiceToPay = { ...value }
  }

  get getMoneyCode() {
    if (this.currentBankAccount.currency) {
      return this.currencies.find(c => c.uuid == this.currentBankAccount.currency)?.code.toUpperCase();
    }
    return ''
  }

  get accountAlreadyExists() {
    if (this.currentBankAccount && this.currentBankAccount.account_no && this.currentBankAccount.bank && !this.isEditing) {
      return this.bankAccounts.filter(ba => ba.account_no == this.currentBankAccount.account_no && (ba.bank as any).uuid == this.currentBankAccount.bank).length > 0
    }
    return false
  }

  get accountTypeId() {
    if (this.accountTypes) {
      return this.accountTypes.find(accType => accType.code.toLowerCase() === "cb")?.uuid
    }
    return ''
  }
  
  async validateForm(saveAndContinue=false) {
    const isFormValid = await (this.$refs['ba-form'] as any).validate()

    if (!isFormValid || !this.currentBankAccount.sub_type || this.accountAlreadyExists) {
      this.showErrors = true;
      return;
    }

    if (isFormValid && !saveAndContinue && !this.accountAlreadyExists) {
      this.saveBankAccount()
    }

    if (isFormValid && saveAndContinue && !this.accountAlreadyExists) {
      this.saveAndContinue()
    }
  }

  formatBankAccount(bankAccount: BankAccount): BankAccount {
    return {
      ...bankAccount,
      type: this.accountTypeId ? this.accountTypeId : ""
    }
  }

  created() {
    this.$root.$on('clearBankAccount', () => {
      this.clearBankAccount();
    });
    this.$root.$on('editBankAccount', (editingBankAccount: BankAccount) => {
      this.editBankAccount(editingBankAccount);
    });
  }

  saveAndContinue() {
    this.closeModal('bank-account-modal');
    const bankAccount = this.formatBankAccount(this.currentBankAccount);
    this.$store.dispatch("Asset/saveBankAccount", { payload: bankAccount, isEditing: this.isEditing, userId: this.user.id })
    this.clearBankAccount();
    setTimeout(this.openModal, 1000);
    setTimeout(() => {
      this.$store.dispatch("Dashboard/setPatrimony", {userId: this.user.id, moneda: this.defaultCurrencyCode});
      this.$store.dispatch("Budget/getTransactionAccounts");
    }, 1000);
  }

  openModal() {
    UIkit.modal(document.getElementById('bank-account-modal')).show()
  }

  saveBankAccount() {
    const bankAccount = this.formatBankAccount(this.currentBankAccount);
    this.$store.dispatch("Asset/saveBankAccount", { payload: bankAccount, isEditing: this.isEditing, userId: this.user.id })
    this.clearBankAccount();
    this.closeModal('bank-account-modal');
    setTimeout(() => {
      this.$store.dispatch("Dashboard/setPatrimony", {userId: this.user.id, moneda: this.defaultCurrencyCode});
      this.$store.dispatch("Budget/getTransactionAccounts");
    }, 1000);

    if (this.calledFromDashboard.redirect) {
      this.openTransactionModal(this.calledFromDashboard.to);
    } 
  }

  openTransactionModal(to: string) {
    this.$store.dispatch("Dashboard/setProductCallFromDashboard", { redirect: false, to: '', stopServiceReset: true} as TransactionRedirection);
    this.$store.dispatch('Budget/setServiceToPay', this.localServiceToPay);
    this.localServiceToPay = {} as ServiceToPay;
    
    ($('#transactionModal') as any).modal();
    const el = document.getElementById(to);
    el?.click();
  }

  clearBankAccount() {
    const newBankAccount = {} as BankAccount
    this.currentBankAccount = newBankAccount;
    this.isEditing = false;
    this.resetFormValidation();
  }

  resetFormValidation() {
    (this.$refs['ba-form'] as any).reset();
    this.showErrors = false;
  }

  editBankAccount(editingBankAccount: BankAccount) {
    UIkit.modal(document.getElementById('bank-account-modal')).show()
    const bankToBeEdited : BankAccount = {
      ...editingBankAccount,
      balance: typeof editingBankAccount.balance == "number" ? editingBankAccount.balance : parseFloat(editingBankAccount.balance),
      sub_type: (editingBankAccount.sub_type as any).uuid,
      currency: (editingBankAccount.currency as any).uuid
    }
    // const formattedBank = this.bankList.filter(bank => bank.uuid == editingBankAccount.banco.uuid)[0]
    // bankToBeEdited.banco = {...formattedBank};
    const formattedBank = this.bankList.filter(bank => bank.uuid == (editingBankAccount.bank as any).uuid)[0]
    bankToBeEdited.bank = formattedBank.uuid;
    this.currentBankAccount = bankToBeEdited;
    this.isEditing = true;
  }
}
