import BaseApiService from "./BaseApiService";
import BaseServiceResponse from "@/models/BaseServiceResponse";
import SurveyServiceResponse from "@/models/Enrollment/SurveyServiceResponse";
import { BudgetCategory, BudgetSubCategory, BudgetSubCategoryEdit } from "@/models/Budget/BudgetCategory";
import UserBudget from "@/models/Budget/UserBudget";
import BudgetTransaction from "@/models/Budget/BudgetTransaction";
import { generateGUID } from "@/utils/HelperMethods";
import { AxiosResponse } from "axios";
import BudgetConfiguration from "@/models/Budget/BudgetConfiguration";
import NewBudgetRequest from "@/models/Budget/NewBudgetRequest";
import AppSettings from "@/AppSettings";
import store from "@/store";

export default class BudgetService extends BaseApiService {
    public constructor() {
        super()
        this.apiUrl = AppSettings.API_URL + 'finance/'
    }

    public GetUserBudgets() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<{}, UserBudget[]>(`budgets/?subscription=${subscription}`)
    }

    public RetrieveUserBudget(budgetId: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.retrieve<{ budgetId: string }, UserBudget>("budgets/", budgetId, subscription)
    }

    public CreateUserBudget(budgetRequest: NewBudgetRequest) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.post<NewBudgetRequest, UserBudget>("budgets/", budgetRequest)
    }

    public DeleteUserBudget(budgetId: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.delete<string, {}>("budgets/", budgetId, subscription)
    }

    public CreateBudgetCategory(cat: BudgetCategory) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        cat.subscription = subscription;
        return this.post<BudgetCategory, BudgetCategory>("categories/", cat)
    }

    public CreateBudgetSubCategory(subCategory: BudgetSubCategory) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        subCategory.subscription = subscription;
        return this.post<BudgetSubCategory, BudgetSubCategory>("subcategories/", subCategory)
    }

    public EditBudgetCategory(updatedCatProperties: any) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        updatedCatProperties.subscription = subscription;
        return this.patch<BudgetCategory, BudgetCategory>("categories/", updatedCatProperties, updatedCatProperties.uuid, subscription)
    }

    public EditBudgetSubCategory(updatedSubCat: BudgetSubCategoryEdit) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        updatedSubCat.subscription = subscription;
        return this.patch<BudgetSubCategoryEdit, BudgetCategory>("subcategories/", updatedSubCat, updatedSubCat.uuid, subscription)
    }

    public DeleteBudgetCategory(catId: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.delete<string, string>("categories/", catId, subscription);
    }

    public DeleteBudgetSubCategory(subCatId: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.delete<string, string>("subcategories/", subCatId, subscription);
    }

    public GetTransactions() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        const activeBudget = store.getters['Budget/getActiveBudget'].uuid;
        return this.get<{}, BudgetTransaction[]>(`transacctions/budget_transactions/?subscription=${subscription}&budget=${activeBudget}`)
    }

    public GetGlobalTransactions() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        const activeBudget = store.getters['Budget/getActiveBudget'].uuid;
        return this.get<{}, BudgetTransaction[]>(`transacctions/?subscription=${subscription}`)
    }

    public CreateTransactions(transaction: BudgetTransaction) {
        transaction.subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.post<BudgetTransaction, BudgetTransaction>("transacctions/", transaction)
    }

    public DeleteTransactions(transactionId: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.delete<string, {}>("transacctions/", transactionId, subscription)
    }

    public SaveSubCategoryChanges(userId: string,
        budgetDate: string,
        category: BudgetCategory,
        subCategory: BudgetSubCategory) {
        let categoryCopy = { ...category }
        categoryCopy.subcategories = [subCategory];
        let request = {
            userId: userId,
            budgetDate: budgetDate,
            category: {
                ...categoryCopy
            },
            accion: "M"
        }
        console.log(JSON.stringify(request));
        return this.post<Object, Object>("registrar-presupuesto-usuario-sitioweb", request)
    }

    public GetEnrollmentCategories(userId: string) {

        // let cate: BudgetCategory = { "categoria": "Deporte", "categoryColor": "blue", "fontColor": "", "id": "b9905846-b5c3-4496-a675-881005073b0c", "subcategorias": [{ "id": "df731d24-3268-4c9a-9e0b-vebddc92d623", "montoEjecutado": 0, "montoPresupuestado": 0, "subcategoria": "Vitaminas" }, { "id": "69de7816-e99d-48cf-ba3b-0d8e615587ae", "montoEjecutado": 0, "montoPresupuestado": 0, "subcategoria": "Ropa deportiva" }], "tipo": CategoryType.Expense, "urlIcono": "" }
        // let cate2: BudgetCategory = { "categoria": "Mascotas", "categoryColor": "pink", "fontColor": "", "id": "q2905846-b5c3-4496-a675-881005073b0c", "subcategorias": [{ "id": "a3731d24-3268-4c9a-9e0b-vebddc92d623", "montoEjecutado": 0, "montoPresupuestado": 0, "subcategoria": "Comida de mascotas" }, { "id": "69de7816-e99d-48cf-ba3b-0d8e615565ae", "montoEjecutado": 0, "montoPresupuestado": 0, "subcategoria": "Peluquería" }], "tipo": CategoryType.Expense, "urlIcono": "" }

        // return new Promise<BudgetCategory[]>((resolve, reject) => {

        //     setTimeout(() => {
        //         resolve([cate, cate2])
        //     }, 1000)
        // })
        return this.post<{userId:string}, BudgetCategory[]>("obtener-cat-encuesta-sin-presupuesto-sitioweb", {userId})
    }

    public DeleteSubCategory(userId: string,
        budgetDate: string,
        category: BudgetCategory,
        subCategory: BudgetSubCategory) {
        let categoryCopy = { ...category }
        categoryCopy.subcategories = [subCategory];
        let request = {
            userId: userId,
            budgetDate: budgetDate,
            category: {
                ...categoryCopy
            },
            accion: "D"
        }
        console.log(JSON.stringify(request));
        return this.post<Object, Object>("registrar-presupuesto-usuario-sitioweb", request)
    }

    public DeleteCategory(userId: string, 
        budgetDate: string,
        category: BudgetCategory) {
        let categoryCopy = { ...category }
        categoryCopy.subcategories = [];
        let request = {
            userId: userId,
            budgetDate: budgetDate,
            accion: "D",
            category: categoryCopy,
        }
        return this.post<Object, Object>("actualizar-cat-presupuesto-usuario-sitioweb", request)
    }

    public GetUserTransactions(userId: string, budgetDate: string) {
        let request = {
            userId: userId,
            budgetDate
        }
        return this.post<Object, BudgetTransaction[]>("obtener-transacciones-usuario-sitioweb", request)
    }

    public SaveTransaction(userId: string, transaction: BudgetTransaction) {
        let action = "M"
        if (!transaction.uuid)
            transaction.uuid = generateGUID();
        let request = {
            userId: userId,
            accion: action,
            transacciones:[
                transaction
            ]
        }
        return this.post<Object, BudgetTransaction[]>("registrar-transaccion-usuario-sitioweb", request)
    }
    public DeleteTransaction(userId: string, transaction: BudgetTransaction) {
        let request = {
            userId: userId,
            accion: "D",
            transacciones:[
                transaction
            ]
        }
        return this.post<Object, BudgetTransaction[]>("registrar-transaccion-usuario-sitioweb", request)
    }

    public GetConfigurations(userId: string) {
        return this.post<{userId:string}, BudgetConfiguration>("obtener-configuraciones-presupuesto-sitioweb", {userId})
    }

    public ChangeBudgetCategoryOrder(userId: string,
        budgetDate: string,
        categories:  BudgetCategory[]) {
        let request = {
            idUsuario: userId,
            budgetDate: budgetDate,
            categories: categories
        }
        console.log(JSON.stringify(request));
        return this.post<Object, Object>("reorganizar-presupuesto-usuario-sitioweb", request)
    }

    public DeleteBudget(userId: string, budgetDate: string) {
        let request = {
            userId: userId,
            budgetDate: budgetDate
        }
        return this.post<Object, Object>("eliminar-presupuesto-usuario-sitioweb", request)
    }

    public GetUserTransactionByType(request: any) {
        return this.post<Object, Object>("obtener-cuentas-transaccion-usuario-sitioweb", request)
    }
}