
import { Component, Vue } from "vue-property-decorator";
import Cookies from "js-cookie";
import { Getter } from "vuex-class";

@Component({
    components: {
    }
})
export default class InstallationPrompt extends Vue {
    deferredPrompt:any = null;
    @Getter("Layout/isMobileView") isMobile!: boolean;
    //
    created() {
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            // Stash the event so it can be triggered later.
            //Also check for cookie existance to see if it is necessary to prompt for installation
            if (Cookies.get("install-prompt") === undefined) {
                this.deferredPrompt = e;
            }
        });
        window.addEventListener("appinstalled", () => {
            this.deferredPrompt = null;
        });
    }

    dismiss() {
        Cookies.set("install-prompt", null, { expires: 3 });
        this.deferredPrompt = null;
    }

    install() {
        this.deferredPrompt.prompt();
    }
}
