
import { Component, Vue, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import { Loan, AccountType as UserAccountType } from "@/models/UserCatalog/UserCatalog"
import { Currency } from '@/models/Common/Common'
import { Bank, AccountType } from '@/models/Catalog/Catalog'
import UIkit from 'uikit';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { CurrencyCode } from "@/components/assetsLiabilities/mixins";
import { CurrencyInput, setValue }  from 'vue-currency-input';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    CurrencyInput
  }
})

export default class AddLoanModal extends Mixins(CurrencyCode) {
  currentLoan = {} as Loan
  @Getter("Asset/getCurrencies") currencies!: Currency[];
  @Getter("Asset/getBanks") bankList!: Bank[];
  @Getter("Asset/getLoanSubTypes") loanAccountType!: AccountType;
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  isEditing = false
  money = {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false
  }
  interestRate = {
    decimal: '.',
    thousands: '',
    prefix: '',
    suffix: '%',
    precision: 2,
    masked: false
  }
  loadedCurrencies = false;
  showMoneyErrors = false;
  currentBalance = 0.00
  
  get currencyCode() {
    if (this.currentLoan.currency) {
      return this.currencies.find(c => c.uuid == this.currentLoan.currency)?.code.toUpperCase();
    }
    return ''
  }

  async validateForm(saveAndContinue=false) {
    const isFormValid = await (this.$refs['loan-form'] as any).validate()

    if (!isFormValid) {
      this.showMoneyErrors = true;
    }

    if (isFormValid && !saveAndContinue) {
      this.saveLoan()
    }

    if (isFormValid && saveAndContinue) {
      this.saveAndContinue()
    }
  }

  resetFormValidation() {
    (this.$refs['loan-form'] as any).reset();
    this.showMoneyErrors = false;
  }

  closeModal() {
    UIkit.modal(document.getElementById('loan-modal')).hide();
  }

  openModal() {
    UIkit.modal(document.getElementById('loan-modal')).show();
  }

  formatLoan(loan: Loan) {
    return {
      ...loan,
      payed_amt: loan.amt - this.currentBalance
    }
  }

  created() {
    this.$root.$on('clearLoan', () => {
      this.clearLoan();
    });
    this.$root.$on('editLoan', (editingLoan: Loan) => {
      this.editLoan(editingLoan);
    });
  }

  saveAndContinue() {
    const loanToSave = this.formatLoan(this.currentLoan);
    this.$store.dispatch("Asset/saveLoan", { payload: loanToSave, isEditing: this.isEditing, userId: this.user.id })
    this.clearLoan();
    this.closeModal();
    setTimeout(this.openModal, 1000);
    setTimeout(() => {
      // this.$store.dispatch("Dashboard/setPatrimony", {userId: this.user.id, moneda: this.defaultCurrencyCode});
    }, 1000);
  }

  saveLoan() {
    const loanToSave = this.formatLoan(this.currentLoan);
    this.$store.dispatch("Asset/saveLoan", { payload: loanToSave, isEditing: this.isEditing, userId: this.user.id })
    this.clearLoan();
    this.closeModal();
    setTimeout(() => {
      // this.$store.dispatch("Dashboard/setPatrimony", {userId: this.user.id, moneda: this.defaultCurrencyCode});
    }, 1000);
  }

  clearLoan() {
    const newLoan = {} as Loan
    this.currentLoan = newLoan;
    this.isEditing = false;
    this.resetFormValidation();
    this.currentBalance = 0;
  }

  editLoan(editingLoan: Loan) {
    const loanToBeEdited = {
      ...editingLoan,
      amt: typeof editingLoan.amt == "number" ? editingLoan.amt : parseFloat(editingLoan.amt),
      payed_amt: typeof editingLoan.payed_amt == "number" ? editingLoan.payed_amt : parseFloat(editingLoan.payed_amt),
      fee: typeof editingLoan.fee == "number" ? editingLoan.fee : parseFloat(editingLoan.fee),
      rate: typeof editingLoan.rate == "number" ? editingLoan.rate : parseFloat(editingLoan.rate),
      sub_type: (editingLoan.sub_type as any).uuid,
      bank: (editingLoan.bank as any).uuid,
      currency: (editingLoan.currency as any).uuid
    };
    this.currentBalance = loanToBeEdited.amt - loanToBeEdited.payed_amt;
    this.currentLoan = loanToBeEdited;
    this.isEditing = true;
  }
}
