import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, confirmed, min, length, min_value, max } from 'vee-validate/dist/rules';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

extend('required', {
    ...required,
    message: 'Este campo es requerido'
});

extend('confirmed', {
    ...confirmed,
    message: 'Las contraseñas no coinciden.'
});


extend('telefono', {
    validate: (value) => {
        const phoneREG = /^1?(809|829|849)-?\d{3}-?\d{4}$/;
        return phoneREG.test(value);
    },
    message: 'Número de teléfono inválido.'
});

extend('email', {
    validate: (value) => {
        if(value)
        {
            //https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
            const EMAILREG = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            return EMAILREG.test(value);
        }
        else{
            return true;
        }
    },
    message: 'Este no es un correo electrónico valido.'
});

extend('lowercase', {
    validate: (value) => {
        if (value) {
            return !!(value.replace(/[^a-zA-Z]/gm, '').split('').find((x: string) => x === x.toLowerCase()));
        }
        return false;
    },
    message: 'La clave debe contener al menos una (1) letra minúscula.'
});

extend('uppercase', {
    validate: (value) => {
        if (value) {
            return !!(value.replace(/[^a-zA-Z]/gm, '').split('').find((x: string) => x === x.toUpperCase()));
        }
        return false;
    },
    message: 'La clave debe contener al menos una (1) letra mayúscula.'
});

extend('containsNumber', value => {
    if (/\d/.test(value)) {
        return true;
    };
    return 'La clave debe contener al menos un (1) número.'
});

extend('specialCharacter', value => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(value)) {
        return true;
    }
    return 'La clave debe contener al menos un (1) caracter especial (_ @ * - ^ . :) .'
});

extend('onlyLetters', value => {
    const format = /^[a-zA-Z\s]+$/;
    if (format.test(value)) {
        return true;
    }
    return 'No se permiten números ni caracteres especiales, solo letras.'
});

extend('creditCardDateValidation', value => {
    const format = /^((0[1-9])|(1[0-2]))[\/\.\-]*((2[3-9]))$/;
    if (format.test(value)) {
        return true;
    }
    return 'El formato de fecha es MM/YY. El año debe ser entre 2023-2029.'
});

extend('mymax-validation', {
    validate: (value, params) => {
        var result = value && value.length <= params.length;
        return result;
    },
    message: 'Este campo solo admite {length} o menos caracteres.',
    params: ['length'],
});

extend("min", {
    ...min,
    message: "Esta campo debe contener {length} caracteres o más",
  });

extend("max", {
    ...max,
    message: "Esta campo debe contener máximo {length} caracteres.",
  });

extend("length", {
    ...length,
    message: "Este campo debe contener exactamente 4 caracteres.",
  });

extend("moreThan0", {
    validate: (value) => {
        return value > 0;
    },
    message: "El valor debe de ser mayor a 0.",
});

extend("moreThanOrEqual0", {
    validate: (value) => {
        return value >= 0;
    },
    message: "El valor debe de ser mayor o igual a 0.",
});

extend("lessThan100", {
    validate: (value) => {
        return value <= 100;
    },
    message: "La tasa máxima es 100.",
});

extend('min_value', {
    ...min_value,
    message: 'El valor debe ser mayor a 0.'
});

extend('emptyObj', {
    validate: (value) => {
        return Object.entries(value).length > 0;
    },
    message: 'Este campo es requerido'
});

extend('isSmaller', {
    params: ['target'],
    validate(value, { target }:any) {
      return value <= target;
    },
    message: 'Excede el valor del préstamo'
  });