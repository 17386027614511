import { extend } from "vue/types/umd";
import BaseApiService from "./BaseApiService";
import BaseServiceResponse from "@/models/BaseServiceResponse"
import LoginModel from "@/models/IdentityManagement/LoginModel";
import UserModel from "@/models/IdentityManagement/UserModel";
import RegisterModel from "@/models/IdentityManagement/RegisterModel";
import TokenValidationModel from "@/models/IdentityManagement/TokenValidationModel";
import ResetPasswordModel from "@/models/IdentityManagement/ResetPasswordModel";
import UserEnrollmentCheck from "@/models/IdentityManagement/UserEnrollmentCheck";
import store from "@/store";
import { PlanRequest } from "@/models/Plans";

export default class IdentityManagementService extends BaseApiService {

    public Login(loginModel: LoginModel) {
        return this.post<LoginModel, UserModel>("iniciar-sesion-sitioweb", loginModel)
    }

    public Register(userId: string){
        let request = {
            userId
        }
        return this.post<{ userId: string}, object>("registrar-usuario-sitioweb", request)
    }

    public UserDidEnrollment(userId: string){
        let request = {
            userId
        };
        return this.post<{ userId: string}, UserEnrollmentCheck>("validar-enrollment-usuario-sitioweb", request)
    }

    public UserDetails(userId: string){
        let request = {
            idUsuario: userId
        };
        return this.post<{ idUsuario: string}, UserEnrollmentCheck>("obtener-detalle-perfil-usuario-sitioweb", request)
    }

    public UserSubscriptions(){
        return this.get("security/me/")
    }

    public UserBudgetTemplates(){
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get(`security/me/budget_templates/?subscription=${subscription}`)
    }

    public GetPlans(){
        return this.get("core/plans/")
    }

    public ChangePlans(planRequest: PlanRequest){
        planRequest.subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.post<PlanRequest, Object>("subscription/change_plan_request/", planRequest)
    }

    public CreateUserSubscription(userId: string, plan: string){
        let payload = {
            uuid: userId,
            plan: plan
        }
        return this.post("subscription/onboarding/", payload)
    }

    public ConfirmUserSubscription(susbcriptionId: string){
        return this.get(`subscription/onboarding/?subscription=${susbcriptionId}`)
    }

    public RecoverPassword(email: string){
        return this.post<{correoElectronico:string}, object>("recuperar-clave-sitioweb", { correoElectronico: email })
    }
    public ValidateToken(tokenValidationModel: TokenValidationModel){
        return this.post<TokenValidationModel, object>("validar-token-sitioweb", tokenValidationModel)
    }
    public ResetPassword(resetPasswordModel: ResetPasswordModel){
        return this.post<ResetPasswordModel, object>("actualizar-clave-sitioweb", resetPasswordModel)
    }
    public GetCustomerPortal(){
        let subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<object, object>(`security/me/pg_customer_portal/?subscription=${subscription}`)
    }
}