import BaseApiService from "./BaseApiService";
import store from "@/store";
import AppSettings from "@/AppSettings";
import BudgetTransaction from "@/models/Budget/BudgetTransaction";

export default class DashboardService extends BaseApiService {

    public GetGeneralBalance() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<Object, any>(`finance/budgets/current_picture/?subscription=${subscription}`)
    }
    
    public GetPatrimony() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<Object, any>(`catalog/patrimony?subscription=${subscription}`)
    }

    public GetBehaviourInTime(userId: string, moneda:string) {
        let request = {
            idUsuario: userId,
            moneda: moneda
        }
        this.apiUrl = AppSettings.API_URL;
        return this.post<Object, any>("dashboard-obtener-comportamiento-sitioweb", request)
    }

    public GetUserDashboardTransactions() {
        this.apiUrl = AppSettings.API_URL;
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<Object, any>(`finance/transacctions/latest_transactions/?subscription=${subscription}`)
    }

    public GetGlobalTransactions() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<{}, BudgetTransaction[]>(`finance/transacctions/?subscription=${subscription}`)
    }

    public GetDueServices() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<Object, any>(`catalog/services/pending_for_payment/?subscription=${subscription}`)
    }

    public GetFinancialHealth(email: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];    
        return this.get<Object, any>(`security/me/financial_health_score/?subscription=${subscription}`)
    }

    public GetEvents() {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];    
        return this.get<any, any>(`commons/events/?subscription=${subscription}`)
    }

    public GetEventRegistrationLink(eventId: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];    
        return this.get<any, any>(`commons/events/${eventId}/?subscription=${subscription}`)
    }
}