import Vue from 'vue';
import Component from 'vue-class-component';
import UIkit from 'uikit';
import { Currency } from '@/models/Common/Common';
import { Getter } from "vuex-class";
import store from '@/store'

@Component
export class ModalHelper extends Vue {
  hello = 'hello'
  money = {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false
  }
  emptyCurrencyState = {} as Currency;

  closeModal(modalName: string) {
    UIkit.modal(document.getElementById(modalName)).hide();
  }
}

@Component
export class CurrencyCode extends Vue {
    @Getter("Asset/getDefaultCurrencyCode") defaultCurrencyCode!: String;
}

@Component
export class ContainerComponent extends Vue {
  money = {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false
  }

  loadTransactions(idCuenta: string, type: string) {
    this.$store.dispatch('Asset/getAccountTransactions', { idCuenta: idCuenta, type: type });
  }
  selectedAccountCurrency(currency: string) {
    this.$store.dispatch('Asset/setSelectedAccountCurrency', currency);
  }
}