import BaseApiService from "./BaseApiService";
import { FullCatalog, Bank, Currency, ServiceType, AccountType, TransactionAccount } from '@/models';
import { BankAccount, CreditCard, Service, IOLoan, Loan } from "@/models/UserCatalog/UserCatalog";
import store from "@/store";

enum AssetCatalog {
    Bank =  "bancos",
    Currency = "monedas",
    AccountType = "tiposCuentas",
    Services = "tipoServicios"
}

type FinancialAssets = BankAccount | CreditCard | Loan | Service

export default class CatalogService extends BaseApiService {

    public GetBanks() {
        return this.get<Object, BankAccount>("obtener-catalogos-sitioweb", { pais: "DO", nombre: AssetCatalog.Bank })
    }

    public GetAccountTypes() {
        return this.post<{ pais: string, nombre: string }, AccountType[]>("obtener-catalogos-sitioweb", { pais: "DO", nombre: AssetCatalog.AccountType })
    }

    public GetCurrencies() {
        return this.post<{ pais: string, nombre: string }, Currency[]>("obtener-catalogos-sitioweb", { pais: "DO", nombre: AssetCatalog.Currency })
    }

    public GetEndpoint(productType: string) {
        if (productType == "bankAccount") return "accounts"
        if (productType == "creditCard") return "credit_cards"
        if (productType == "loan") return "loans"
        return "services"
    } 

    public GetAllFinancialProducts(product: string) {
        const endpoint = this.GetEndpoint(product);
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<Object, FinancialAssets[]>(`catalog/${endpoint}/?subscription=${subscription}`)
    }

    public GetAccountsForTransactions(transactionType: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<Object, TransactionAccount[]>(`catalog/accounts_available_ftt/?subscription=${subscription}&transaction_type=${transactionType}`)
    }

    public CreateFinancialProduct(product: FinancialAssets, type: string) {
        const endpoint = this.GetEndpoint(type);
        product.subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.post<FinancialAssets, FinancialAssets[]>(`catalog/${endpoint}/`, product)
    }

    public EditFinancialProduct(product: FinancialAssets, type: string) {
        const endpoint = this.GetEndpoint(type);
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        product.subscription = subscription;
        return this.patch<FinancialAssets, FinancialAssets[]>(`catalog/${endpoint}/`, product, product.uuid, subscription)
    }

    public DeleteFinancialProduct(productId: string, type: string) {
        const endpoint = this.GetEndpoint(type);
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.delete<FinancialAssets, FinancialAssets[]>(`catalog/${endpoint}/`, productId, subscription)
    }

    public GetCatalog() {
        return this.post<{ pais: string }, FullCatalog>("obtener-catalogos-sitioweb", { pais: "DO" })
    }

    public SaveAccount(idUsuario: string, tipo: string, accion: string, data: Object) {
        let account = {
            idUsuario,
            tipo,
            accion,
            data
        };
        return this.post<Object, Object>("actualizar-cuentas-usuarios-sitioweb", account)        
    }

    public UpdateService(idUsuario: string, tipo: string, accion: string, data: Object) {
        let account = {
            idUsuario,
            tipo,
            accion,
            data
        };
        return this.post<Object, Object>("actualizar-servicios-usuario-sitioweb", account)        
    }

    public DeleteAccount(idUsuario: string, tipo: string, accion: string, data: Object) {
        let account = {
            idUsuario,
            tipo,
            accion,
            data
        }
        
        return this.post<Object, Object>("actualizar-cuentas-usuarios-sitioweb", account)        
    }

    public GetUserCatalog(idUsuario: string) {
        return this.post<{ idUsuario: string }, any>("obtener-catalogos-usuario-sitioweb", { idUsuario: idUsuario } )
    }

    public GetAccountTransaction(accountId: string, type: string) {
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.get<Object, Object>(`catalog/${type}/${accountId}/transactions/?subscription=${subscription}`);
    }
}