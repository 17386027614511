import { ActionContext } from "vuex";
import { Bank, Currency, ServiceType, AccountType, FullCatalog, AccountTypeSubList } from '@/models';
import CatalogService from "@/services/CatalogService";
import CommonService from "@/services/CommonService";
import { BankAccount, Service, CreditCard, CreditCardBalance, Loan, IOLoan } from "@/models/UserCatalog/UserCatalog";
import BudgetTransaction from "@/models/Budget/BudgetTransaction";
import { Auth } from 'aws-amplify';
import { BIconBank } from "bootstrap-vue";
import { Plan } from "@/models/Plans";
import IdentityManagementService from "@/services/IdentityManagementService";
import { ToastNotification } from "@/models/Notification/UserNotification";


interface AssetStore {
    banks: Bank[];
    accountTypes: AccountType[];
    currencies: Currency[];
    services: ServiceType[];
    fullCatalog?: FullCatalog;
    isAssetLoading: boolean,
    UserBankAccounts: BankAccount[],
    UserServices: Service[],
    UserCreditCards: CreditCard[]
    UserLoans: Loan[],
    showServiceModal: boolean,
    defaultCurrency: Currency,
    budgetTransactions: BudgetTransaction[],
    showTransactionInAssets: boolean,
    selectedAccount: string,
    selectedAccountCurrency: string,
    isLoadingServices: boolean,
    plans: Plan[]
}

const catalogService = new CatalogService();
const commonService = new CommonService();
const identityService = new IdentityManagementService();

function initialState(): AssetStore {
    return {
        banks: [],
        accountTypes: [],
        currencies: [],
        services: [],
        isAssetLoading: true,
        UserBankAccounts: [],
        UserServices: [],
        UserCreditCards: [],
        UserLoans: [],
        showServiceModal: false,
        defaultCurrency: {} as Currency,
        budgetTransactions: [],
        showTransactionInAssets: false,
        selectedAccount: "",
        selectedAccountCurrency: "",
        isLoadingServices: false,
        plans: []
    }
}

const state = initialState();

export const getters = {
    getPlans(state: AssetStore) {
        return state.plans;
    },
    getBanks(state: AssetStore) {
        return state.banks;
    },
    getCurrencies(state: AssetStore) {
        return state.currencies;
    },
    getBankSubTypes(state: AssetStore) {
        return state.accountTypes.find((accType: AccountType) => accType.code.toLowerCase() == 'cb')?.accountsubtype_set
    },
    getLoanSubTypes(state: AssetStore) {
        return state.accountTypes.find((accType: AccountType) => accType.code.toLowerCase() == 'pr')?.accountsubtype_set
    },
    getIsAssetLoading(state: AssetStore) {
        return state.isAssetLoading
    },
    getAccountTypes(state: AssetStore) {
        return state.accountTypes
    },
    getDefaultCurrency(state: AssetStore) {
        return state.defaultCurrency
    },
    getUserBankAccounts(state: AssetStore) {
        return state.UserBankAccounts;
    },
    getUserCreditCards(state: AssetStore) {
        return state.UserCreditCards;
    },
    getUserLoans(state: AssetStore) {
        return state.UserLoans;
    },
    getServices(state: AssetStore) {
        return state.services
    },
    getUserServices(state: AssetStore) {
        return state.UserServices
    },
    // BANK ACCOUNT SUMMARY
    getTotalBankAccountBalanceDOP(state: AssetStore, getters: any) {
        const bankMap = state.UserBankAccounts.filter(ba => (ba.currency as any).uuid === getters.getDOPCurrency)
                            .map(i => +i.balance)
        if (!bankMap.length) {
            return 0
        }
        return bankMap.reduce((a, b) => a + b)
    },
    getTotalBankAccountBalanceUSD(state: AssetStore, getters: any) {
        const bankMap = state.UserBankAccounts.filter(ba => (ba.currency as any).uuid === getters.getUSDCurrency)
                            .map(i => +i.balance)
        if (!bankMap.length) {
            return 0
        }
        return bankMap.reduce((a, b) => a + b)
    },
    // CREDIT CARD GETTERS
    getTotalCreditDOP(state: AssetStore, getters: any) {
        if (state.UserCreditCards.length < 1) return 0;
        const creditDOPMap = state.UserCreditCards.map(c => c.balances)
                .flat().filter(filtered => (filtered.currency as any).code.toUpperCase() === 'DOP')
                .map(s => +s.limit - +s.updated_used);
        if (!creditDOPMap.length) {
            return 0;
        }
        return creditDOPMap.reduce((a,b) => a + b);
    },
    getTotalCreditUSD(state: AssetStore, getters: any) {
        if (state.UserCreditCards.length < 1) return 0;
        const creditUSDMap = state.UserCreditCards.map(c => c.balances)
                .flat().filter(filtered => (filtered.currency as any).code.toUpperCase() === 'USD')
                .map(s => +s.limit - +s.updated_used);
        if (!creditUSDMap.length) {
            return 0;
        }     
        return creditUSDMap.reduce((a,b) => a + b);
    },
    getTotalConsumedDOP(state: AssetStore, getters: any) {
        if (state.UserCreditCards.length < 1) return 0;
        const consumedDOP = state.UserCreditCards.map(c => c.balances)
                .flat().filter(filtered => (filtered.currency as any).code.toUpperCase() === 'DOP')
                .map(s => +s.updated_used)
        if (!consumedDOP.length) {
            return 0;
        }
        return consumedDOP.reduce((a,b) => a + b)
    },
    getTotalConsumedUSD(state: AssetStore, getters: any) {
        if (state.UserCreditCards.length < 1) return 0;
        const consumedUSD = state.UserCreditCards.map(c => c.balances)
                .flat().filter(filtered => (filtered.currency as any).code.toUpperCase() === 'USD')
                .map(s => +s.updated_used)
        if (!consumedUSD.length) {
            return 0;
        }
        return consumedUSD.reduce((a,b) => a + b)
    },
    // LOANS
    getTotalLoanCreditDOP(state: AssetStore, getters: any) {
        var list = state.UserLoans.filter(item => (item.currency as any).uuid === getters.getDOPCurrency)
                .map(i => +i.amt)
        if (!list.length) { return 0 }
        return list.reduce((a, b) => a + b)
    },
    getTotalLoanCreditUSD(state: AssetStore, getters: any) {
        var list = state.UserLoans.filter(item => (item.currency as any).uuid === getters.getUSDCurrency)
                .map(i => +i.amt)
                
        if (!list.length) { return 0 }
        return list.reduce((a, b) => a + b)
    },
    getTotalLoanBalanceDOP(state: AssetStore, getters: any) {
        const creditList = state.UserLoans.filter(item => (item.currency as any).uuid === getters.getDOPCurrency)
                                        .map(i => +i.amt);

        const payedList = state.UserLoans.filter(item => (item.currency as any).uuid === getters.getDOPCurrency)
                                         .map(i => +i.payed_amt);

        let totalCredit = 0;
        let totalPayed = 0;

        if (creditList.length > 0) {
            totalCredit = creditList.reduce((a, b) => a + b);
        }

        if (payedList.length > 0) {
            totalPayed = payedList.reduce((a, b) => a + b)
        }

        return totalCredit - totalPayed;                                         
    },
    getTotalLoanBalanceUSD(state: AssetStore, getters: any) {
        const creditList = state.UserLoans.filter(item => (item.currency as any).uuid === getters.getUSDCurrency)
        .map(i => +i.amt);
        
        const payedList = state.UserLoans.filter(item => (item.currency as any).uuid === getters.getUSDCurrency)
        .map(i => +i.payed_amt);
        
        let totalCredit = 0;
        let totalPayed = 0;
        
        if (creditList.length > 0) {
            totalCredit = creditList.reduce((a, b) => a + b);
        }
        
        if (payedList.length > 0) {
            totalPayed = payedList.reduce((a, b) => a + b)
        }
        
        return totalCredit - totalPayed;                                      
    },
    getTotalLoanRemainingDOP(state: AssetStore, getters: any) {
        const remainingList = state.UserLoans.filter(item => (item.currency as any).uuid === getters.getDOPCurrency)
                                         .map(i => +i.payed_amt);

        let totalRemaining = 0;

        if (remainingList.length > 0) {
            totalRemaining = remainingList.reduce((a, b) => a + b)
        }

        return totalRemaining;                                         
    },
    getTotalLoanRemainingUSD(state: AssetStore, getters: any) {
        const currencyUSD = state.currencies.find(c => c.code.toLowerCase() === getters.getUSDCurrency)?.uuid
        const remainingList = state.UserLoans.filter(item => item.currency === currencyUSD)
                                         .map(i => +i.payed_amt);

        let totalRemaining = 0;

        if (remainingList.length > 0) {
            totalRemaining = remainingList.reduce((a, b) => a + b)
        }

        return totalRemaining;                                         
    },
    getShowServiceModal(state: AssetStore) {
        return state.showServiceModal;
    },
    getShowTransactionSectionStatus(state: AssetStore) {
        return state.showTransactionInAssets;
    },
    getAccountTransactions(state: AssetStore) {
        return state.budgetTransactions;
    },
    getSelectedAccount(state: AssetStore) {
        return state.selectedAccount;
    },
    getSelectedAccountCurrency(state: AssetStore) {
        return state.selectedAccountCurrency;
    },
    getIsLoadingServices(state: AssetStore) {
        return state.isLoadingServices;
    },
    getDefaultCurrencyCode(state: AssetStore) {
        if (state.defaultCurrency && state.defaultCurrency.code) {
            return state.defaultCurrency.code.toLowerCase()
        }
        return ''
    },
    getDefaultCurrencyCodeForDisplay(state: AssetStore) {
        if (state.defaultCurrency && state.defaultCurrency.code) {
            return state.defaultCurrency.code
        }
        return ''
    },
    getUSDCurrency(state: AssetStore) {
        if (state.currencies) {
            return state.currencies.find(c => c.code.toLowerCase() === 'usd')?.uuid
        }
        return ''
    },
    getDOPCurrency(state: AssetStore) {
        if (state.currencies) {
            return state.currencies.find(c => c.code.toLowerCase() === 'dop')?.uuid
        }
        return ''
    },
}

const mutations = {
    SET_PLANS(state: AssetStore, data: Plan[]) {
        state.plans = data
    },
    SET_IS_LOADING_SERVICES(state: AssetStore) {
        state.isLoadingServices = !state.isLoadingServices
    },
    SET_BANKS_FROM_CATALOG(state: AssetStore, data: Bank[]) {
        state.banks = data;
    },
    SET_CURRENCY_FROM_CATALOG(state: AssetStore, data: Currency[]) {
        state.currencies = data;
    },
    SET_ACCOUNT_TYPES_FROM_CATALOG(state: AssetStore, data: AccountType[]) {
        state.accountTypes = data;
    },
    SET_SERVICES_FROM_CATALOG(state: AssetStore, data: ServiceType[]) {
        state.services = data;
    },
    ASSETS_IS_LOADING(state: AssetStore, data: boolean) {
        state.isAssetLoading = data;
    },
    SET_USER_BANK_ACCOUNTS(state: AssetStore, data: BankAccount[]) {
        state.UserBankAccounts = data;
    },
    SET_USER_CREDIT_CARDS(state: AssetStore, data: CreditCard[]) {
        state.UserCreditCards = data;
    },
    SET_USER_LOANS(state: AssetStore, data: Loan[]) {
        state.UserLoans = data;
    },
    SET_USER_SERVICES(state: AssetStore, data: Service[]) {
        state.UserServices = data;
    },
    SHOW_SERVICE_MODAL(state: AssetStore, show: boolean) {
        state.showServiceModal = !state.showServiceModal;
    },
    SET_DEFAULT_CURRENCY_OBJECT(state: AssetStore, currency: Currency) {
        state.defaultCurrency = currency;
    },
    SET_INITIAL_STATE(state: AssetStore) {
        Object.assign(state, initialState());
    },
    SET_ACCOUNT_TRANSACTIONS(state: AssetStore, transaction: BudgetTransaction[]) {
        state.budgetTransactions = transaction;
    },
    SET_SHOW_ACCOUNT_TRANSACTIONS(state: AssetStore, show: boolean) {
        state.showTransactionInAssets = show;
    },
    SET_SELECTED_ACCOUNT(state: AssetStore, accountId: string) {
        state.selectedAccount = state.selectedAccount === accountId ? "" : accountId;
    },
    SET_SELECTED_ACCOUNT_CURRENCY(state: AssetStore, currencyCode: string) {
        state.selectedAccountCurrency = currencyCode
    }
}

const actions = {
    toggleLoadingServices(context: ActionContext<AssetStore, AssetStore>) {
        context.commit("SET_IS_LOADING_SERVICES");
    },
    bankCatalogLoaded(context: ActionContext<AssetStore, AssetStore>, payload: Bank[]) {
        context.commit("SET_BANKS_FROM_CATALOG", payload)
    },
    assetCatalogLoaded(context: ActionContext<AssetStore, AssetStore>) {
        context.dispatch("setaccountTypes");
        context.dispatch("setDefaultCurrencyObject");
        context.dispatch("setCurrencies");
        context.dispatch("setServiceTypes");
        context.dispatch("setBanks");
    },
    async setaccountTypes(context: ActionContext<AssetStore, AssetStore>) {
        try {
            let response = await commonService.GetAccountTypes()
            context.commit('SET_ACCOUNT_TYPES_FROM_CATALOG', response.data)
        } catch (error) {
            console.log(error)
        }
    },
    async setCurrencies(context: ActionContext<AssetStore, AssetStore>) {
        try {
            let response = await commonService.GetCurrencies()
            context.commit('SET_CURRENCY_FROM_CATALOG', response.data)
            context.dispatch("setDefaultCurrencyObject");
        } catch (error) {
            console.log(error)
        }
    },
    async setBanks(context: ActionContext<AssetStore, AssetStore>) {
        try {
            let response = await commonService.GetBanks()
            context.commit("SET_BANKS_FROM_CATALOG", response.data);
        } catch (error) {
            console.log(error)
        }
    },
    async setServiceTypes(context: ActionContext<AssetStore, AssetStore>) {
        try {
            let response = await commonService.GetServices()
            context.commit('SET_SERVICES_FROM_CATALOG', response.data)
        } catch (error) {
            console.log(error)
        }
    },
    async setDefaultCurrencyObject(context: ActionContext<AssetStore, AssetStore>) {
        let cognitouser = await Auth.currentAuthenticatedUser();
        cognitouser.getUserAttributes((err:any, attributes: any) => {
            var currencyCode = attributes?.find((x:any) => x.Name == "custom:currency")?.Value as string
            // currencyCode = currencyCode ? currencyCode : 'dop';
            currencyCode = 'dop';
            const selectedCurrency = context.getters.getCurrencies.find((c:Currency) => c.code.toLowerCase() === currencyCode.toLowerCase());
            context.commit("SET_DEFAULT_CURRENCY_OBJECT", selectedCurrency);
        });
    },
    changeDefaultCurrency(context: ActionContext<AssetStore, AssetStore>, code:string) {
        const selectedCurrency = context.getters.getCurrencies.find((c:Currency) => c.code.toLowerCase() === code.toLowerCase());
        context.commit("SET_DEFAULT_CURRENCY_OBJECT", selectedCurrency);
    },
    userCatalogLoad(context: ActionContext<AssetStore, AssetStore>) {
        context.dispatch("getBankAccount");
        context.dispatch("getCreditCards");
        context.dispatch("getLoans");
        context.dispatch('userServicesLoad');
    },
    async userServicesLoad(context: ActionContext<AssetStore, AssetStore>, idUsuario: string) {
        try {
            let userServices = await catalogService.GetAllFinancialProducts("service");
            context.commit("SET_USER_SERVICES", userServices.data);
        } catch (error) {
            console.log(error);
        }
    },
    async getBankAccount(context: ActionContext<AssetStore, AssetStore>, idUsuario: string) {
        try {
            let response = await catalogService.GetAllFinancialProducts("bankAccount");
            context.commit('SET_USER_BANK_ACCOUNTS', response.data)
            // context.dispatch("Budget/setIncomeAccounts", response.data, { root: true })
        } catch (error) {
            console.log(error)
        }   
    },
    async saveBankAccount(context: ActionContext<AssetStore, AssetStore>, data: { payload: BankAccount, isEditing: boolean, userId: string }) {
        try {
            if (data.isEditing) {
                let response = await catalogService.EditFinancialProduct(data.payload, "bankAccount")
                if (response.status === 200) {
                    let currentBankAccounts = context.getters.getUserBankAccounts.slice();
                    let editedBankAccountIndex = currentBankAccounts.findIndex((s: Service) => s.uuid == data.payload.uuid);
                    currentBankAccounts.splice(editedBankAccountIndex, 1, response.data);
                    context.commit('SET_USER_BANK_ACCOUNTS', currentBankAccounts);
                }
            } else {
                data.payload.name = data.payload.account_no; 
                let response = await catalogService.CreateFinancialProduct(data.payload, "bankAccount");
                if (response.status === 201) {
                    let updatedBankAccounts = [...context.getters.getUserBankAccounts.slice(), response.data]
                    context.commit('SET_USER_BANK_ACCOUNTS', updatedBankAccounts)
                }
            }
        } catch (error) {
            console.log(error)
            
        }
    },
    async deleteBankAccount(context: ActionContext<AssetStore, AssetStore>, data: { userId: string, bankAccountId: string }) {
        try {
            let response = await catalogService.DeleteFinancialProduct(data.bankAccountId, "bankAccount");
            if (response && response.status === 204) {
                let bankAccounts = context.getters.getUserBankAccounts.filter((ba: BankAccount) => ba.uuid !== data.bankAccountId)
                context.commit('SET_USER_BANK_ACCOUNTS', bankAccounts)
            }
        } catch (error) {
            console.log(error)
            
        }
    },
    async getCreditCards(context: ActionContext<AssetStore, AssetStore>, idUsuario: string) {
        try {
            let response = await catalogService.GetAllFinancialProducts("creditCard");
            context.commit('SET_USER_CREDIT_CARDS', response.data)
            // context.dispatch("Budget/setPaymentAccounts", response.data, { root: true })
        } catch (error) {
            console.log(error)
        }   
    },
    async saveCreditCard(context: ActionContext<AssetStore, AssetStore>, data: { 
        payload: CreditCard, 
        isEditing: boolean,
        userId: string,
        saldoPrincipal: CreditCardBalance,
        saldoSecundario: CreditCardBalance,
        isDoubleBalance: boolean}) {
            data.payload.type = context.getters.getAccountTypes.filter((acc: AccountType) => acc.code.toLowerCase() == 'tc')[0].uuid;

            if (data.isEditing) {
                const editingCreditCard : CreditCard = {
                    ...data.payload,
                    balances: [{...data.saldoPrincipal}],
                    bank: (data.payload.bank as any).uuid
                }

                if (data.isDoubleBalance) {
                    let secondBalance = {...data.saldoSecundario}
                    editingCreditCard.balances.push(secondBalance);
                }
                
                try {
                    let response = await catalogService.EditFinancialProduct(editingCreditCard, "creditCard")
                    if (response && response.status === 200) {
                        let creditCards = context.getters.getUserCreditCards.slice();
                        let editedCreditCardIndex = creditCards.findIndex((cc: CreditCard) => cc.uuid == data.payload.uuid);
                        creditCards.splice(editedCreditCardIndex, 1, response.data);
                        context.commit("SET_USER_CREDIT_CARDS", creditCards);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            else {
                const newCreditCard : CreditCard = {
                    ...data.payload,
                    balances: [data.saldoPrincipal]
                }

                if (data.isDoubleBalance) newCreditCard.balances.push(data.saldoSecundario);
                
                try {
                    let response = await catalogService.CreateFinancialProduct(newCreditCard, "creditCard");
                    if (response && response.status === 201) {
                        let creditCards = context.getters.getUserCreditCards.slice();
                        creditCards.push(response.data)
                        context.commit("SET_USER_CREDIT_CARDS", creditCards);
                    }                    
                } catch (error) {
                    console.log(error);
                }
            }
        },
    async deleteCreditCard(context: ActionContext<AssetStore, AssetStore>, data: { userId: string, creditCardId: string }) {
        try {
            let response = await catalogService.DeleteFinancialProduct(data.creditCardId, "creditCard");
            if (response && response.status === 204) {
                let creditCards = context.getters.getUserCreditCards.filter((cc: CreditCard) => cc.uuid !== data.creditCardId)
                context.commit("SET_USER_CREDIT_CARDS", creditCards);
            }
        } catch (error) {
            console.log(error);
            
        }
    },
    async getLoans(context: ActionContext<AssetStore, AssetStore>, idUsuario: string) {
        try {
            let response = await catalogService.GetAllFinancialProducts("loan");
            context.commit("SET_USER_LOANS", response.data);
        } catch (error) {
            console.log(error)
        }   
    },
    async saveLoan(context: ActionContext<AssetStore, AssetStore>, data: { payload: Loan, isEditing: boolean, userId: string }) {
        try {
            if (data.isEditing) {
                const newLoan : Loan = {
                    ...data.payload,
                    type: context.getters.getAccountTypes.find((type: AccountType) => type.code.toLowerCase() === 'pr').uuid,
                    account_no: data.payload.name
                }
                let response = await catalogService.EditFinancialProduct(newLoan, "loan");
                if (response && response.status === 200) {
                    let loans = context.getters.getUserLoans.slice();
                    let editingLoanIndex = loans.findIndex((loan: Loan) => loan.uuid == data.payload.uuid);
                    loans.splice(editingLoanIndex, 1, response.data);
                    context.commit("SET_USER_LOANS", loans);                
                }
            } else {
                const newLoan : Loan = {
                    ...data.payload,
                    type: context.getters.getAccountTypes.find((type: AccountType) => type.code.toLowerCase() === 'pr').uuid,
                    account_no: data.payload.name
                }
                let response = await catalogService.CreateFinancialProduct(newLoan, "loan");
                if (response && response.status === 201) {
                    let loans = context.getters.getUserLoans.slice();
                    loans.push(response.data)
                    context.commit("SET_USER_LOANS", loans);
                }
            }
        } catch (error) {
            console.log(error);
            
        }
    },
    async deleteLoan(context: ActionContext<AssetStore, AssetStore>, data: { userId:string, loanId: string }) {
        try {
            let response = await catalogService.DeleteFinancialProduct(data.loanId, "loan");
            if (response && response.status === 204) {
                let loans = context.getters.getUserLoans.filter((cc: CreditCard) => cc.uuid !== data.loanId)
                context.commit("SET_USER_LOANS", loans);
            }
        } catch (error) {
            console.log(error);
            
        }
    },
    async saveService(context: ActionContext<AssetStore, AssetStore>, data: { payload: Service, isEditing: boolean, userId: string }) {
        try {
            if (data.isEditing) {
                let response = await catalogService.EditFinancialProduct(data.payload, "services");
                if (response && response.status === 200) {
                    let currentServices = context.getters.getUserServices.slice()
                    let editedServiceIndex = currentServices.findIndex((s: Service) => s.uuid == data.payload.uuid)
                    currentServices.splice(editedServiceIndex, 1, response.data)
                    context.commit('SET_USER_SERVICES', currentServices)
                }
            } else {
                let response = await catalogService.CreateFinancialProduct(data.payload, "service");
                if (response && response.status === 201) {
                    let services = [...context.getters.getUserServices]
                    services.push(response.data)
                    context.commit('SET_USER_SERVICES', services)
                }
            }
        } catch (error) {
            console.log(error);
            
        }
    },
    async deleteService(context: ActionContext<AssetStore, AssetStore>, data: { userId: string, serviceId: string }) {
        try {
            let response = await catalogService.DeleteFinancialProduct(data.serviceId, "service");
            if (response && response.status === 204) {
                let services = context.getters.getUserServices.filter((s: Service) => s.uuid !== data.serviceId)
                context.commit('SET_USER_SERVICES', services)
            }
        } catch (error) {
            console.log(error);
            
        }
    },
    toggleServiceModal(context: ActionContext<AssetStore, AssetStore>, showValue: boolean) {
      context.commit('SHOW_SERVICE_MODAL', showValue);  
    },
    clearData(context: ActionContext<AssetStore, AssetStore>) {
        context.commit('SET_INITIAL_STATE')
    },
    async getAllPlans(context: ActionContext<AssetStore, AssetStore>) {
        try {
            let response = await identityService.GetPlans()
            if (response.status == 200) {
                context.commit("SET_PLANS", response.data)
            }
        } catch (error) {
            console.log(error)
        }
    },
    async getAccountTransactions(context: ActionContext<AssetStore, AssetStore>, data: {idCuenta: string, type: string}) {
        context.commit('SET_SELECTED_ACCOUNT', data.idCuenta);

        if (context.getters.getSelectedAccount == '' && data.idCuenta && context.getters.getShowTransactionSectionStatus) {
            context.commit('SET_SHOW_ACCOUNT_TRANSACTIONS', false);
            return
        }

        try {
            let response = await catalogService.GetAccountTransaction(data.idCuenta, data.type);
            context.commit('SET_SHOW_ACCOUNT_TRANSACTIONS', true);
            context.commit('SET_ACCOUNT_TRANSACTIONS', response.data);
        } catch (error) {
            console.log(error)
        }
    },
    setSelectedAccountCurrency(context: ActionContext<AssetStore, AssetStore>, code: string) {
        context.commit('SET_SELECTED_ACCOUNT_CURRENCY', code);
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}