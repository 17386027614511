
import Component from 'vue-class-component';
import Vue from 'vue';
import UIkit from 'uikit';
import { TransactionRedirection } from '@/models/Common/Common';

@Component({})
export default class SelectProductModal extends Vue {
    createBankAccount() {
        this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
        this.$store.dispatch("Dashboard/setProductCallFromDashboard", { redirect: true, to: 'home-tab' } as TransactionRedirection);
        ($('#selectProductModal') as any).modal('hide');
        this.$root.$emit('clearCreditCard');
        UIkit.modal(document.getElementById('bank-account-modal')).show();
    }

    createCreditCard() {
        this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
        this.$store.dispatch("Dashboard/setProductCallFromDashboard", { redirect: true, to: 'home-tab' } as TransactionRedirection);
        ($('#selectProductModal') as any).modal('hide');
        this.$root.$emit('clearCreditCard');
        UIkit.modal(document.getElementById('credit-card-modal')).show();
    }

    createLoan() {
        ($('#selectProductModal') as any).modal('hide');
        this.$root.$emit('clearLoan');
        UIkit.modal(document.getElementById('loan-modal')).show();
    }

    getImgUrl(icon: string) {
      return require('@/assets/img/svg/' + icon + '-dark-blue.svg');
    }
}
