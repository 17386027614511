import { render, staticRenderFns } from "./AddBankAccountModal.vue?vue&type=template&id=544a9b3d&scoped=true&"
import script from "./AddBankAccountModal.vue?vue&type=script&lang=ts&"
export * from "./AddBankAccountModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddBankAccountModal.vue?vue&type=style&index=0&id=544a9b3d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544a9b3d",
  null
  
)

export default component.exports