const AppSettings = {
    CAUDALL_APP_DOMAIN: process.env.VUE_APP_CAUDALL_APP_DOMAIN as string,
    COGNITO_URL: process.env.VUE_APP_COGNITO_URL as string,
    API_URL: process.env.VUE_APP_API_URL as string,
    API_KEY: process.env.VUE_APP_API_KEY as string,
    COGNITO_REGION: process.env.VUE_APP_COGNITO_REGION as string,
    COGNITO_POOL_ID: process.env.VUE_APP_COGNITO_POOL_ID as string,
    COGNITO_CLIENT_ID: process.env.VUE_APP_COGNITO_CLIENT_ID as string,
    COGNITO_DOMAIN_PREFIX: process.env.VUE_APP_COGNITO_DOMAIN_PREFIX as string,
    COGNITO_DOMAIN: process.env.VUE_APP_COGNITO_DOMAIN as string,
    S3_BUCKET_NAME: process.env.VUE_APP_S3_BUCKET_NAME as string,
    S3_BUCKET_REGION: process.env.VUE_APP_S3_BUCKET_REGION as string,
    SENTRY_DNS: process.env.VUE_APP_SENTRY_DNS as string
}

export default AppSettings;