import { ActionContext } from "vuex";
import UserModel from "@/models/IdentityManagement/UserModel";
import { Subscription } from "@/models";
import { PlanRequest } from "@/models/Plans";
import { Auth } from 'aws-amplify';
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Sentry from "@sentry/vue";
import IdentityManagementService from "@/services/IdentityManagementService";

interface IdentityManagmentStore {
    userEmail: String
    userPasswordResetToken: String,
    user: UserModel | null,
    confirmationEmail: string,
    confirmationUserId: string,
    userCreatedDate: string,
    subscriptions: Subscription[],
    confirmationSubscription: string
}

// State object
function initialState(): IdentityManagmentStore {
    return {
        userEmail: "",
        userPasswordResetToken: "",
        user: null,
        confirmationEmail: "",
        confirmationUserId: "",
        userCreatedDate: "",
        subscriptions: [],
        confirmationSubscription: ""
    }
}

const state = initialState();

const identityService = new IdentityManagementService()

// Getter functions
export const getters = {
    getUserEmail(state: IdentityManagmentStore) {
        return state.userEmail;
    },
    getUserPasswordResetToken(state: IdentityManagmentStore) {
        return state.userPasswordResetToken;
    },
    getUser(state: IdentityManagmentStore) {
        return state.user;
    },
    getConfirmationEmail(state: IdentityManagmentStore) {
        return state.confirmationEmail;
    },
    getConfirmationUserId(state: IdentityManagmentStore) {
        return state.confirmationUserId;
    },
    getUserCreatedDate(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) return new Date(state.subscriptions[0].subscription.created_at)
        return null
    },
    getUserSubscription(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) return state.subscriptions[0].subscription.uuid
        return null
    },
    getUserPlan(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) return state.subscriptions[0].subscription.plan
    },
    getUserSubscriptionStatus(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) return state.subscriptions[0].subscription.status
    },
    getConfirmationSubscription(state: IdentityManagmentStore) {
        return state.confirmationSubscription
    },
    getHasAccessToDashboard(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) {
            if (state.subscriptions[0].subscription.features) return state.subscriptions[0].subscription.features.filter(f => f.feature.code.toLowerCase() == 'custom_dashboard_access' && f.active).length > 0
        }
        return false
    },
    getHasAccessToServices(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) {
            if (state.subscriptions[0].subscription.features) return state.subscriptions[0].subscription.features.filter(f => f.feature.code.toLowerCase() == 'catalog_services' && f.active).length > 0
        }
        return false
    },
    getHasAccessToBudget(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) {
            if (state.subscriptions[0].subscription.features) return state.subscriptions[0].subscription.features.filter(f => f.feature.code.toLowerCase() == 'budgets_access' && f.active).length > 0
        }
        return false
    },
    getCanAddCategories(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) {
            if (state.subscriptions[0].subscription.features) return state.subscriptions[0].subscription.features.filter(f => f.feature.code.toLowerCase() == 'budget_custom_categories' && f.active).length > 0
        }
        return false
    },
    getCanMakeTransactions(state: IdentityManagmentStore) {
        if (state.subscriptions && state.subscriptions.length > 0 && state.subscriptions[0]) {
            if (state.subscriptions[0].subscription.features) return state.subscriptions[0].subscription.features.filter(f => f.feature.code.toLowerCase() == 'budget_transacctions' && f.active).length > 0
        }
        return false
    }
}
// Mutations
const mutations = {
    SET_USER_EMAIL(state: IdentityManagmentStore, data: string) {
        state.userEmail = data;
    },
    SET_USER_PASSWORD_TOKEN(state: IdentityManagmentStore, data: string) {
        state.userPasswordResetToken = data;
    },
    SET_USER(state: IdentityManagmentStore, data: UserModel) {        
        state.user = data;
    },
    SET_CONFIRMATION_EMAIL(state: IdentityManagmentStore, data: string) {
        state.confirmationEmail = data
    },
    SET_CONFIRMATION_USER_ID(state: IdentityManagmentStore, data: string) {
        state.confirmationUserId = data
    },
    SET_ACCOUNT_CREATED_DATE(state: IdentityManagmentStore, date: string) {
        state.userCreatedDate = date
    },
    SET_SUBSCRIPTIONS(state: IdentityManagmentStore, subscriptions: Subscription[]) {
        state.subscriptions = subscriptions
    },
    SET_CONFIRMATION_SUBSCRIPTION(state: IdentityManagmentStore, subscription: string) {
        state.confirmationSubscription = subscription
    },
    SET_INITIAL_STATE(state: IdentityManagmentStore) {
        Object.assign(state, initialState())
    },
}

// Actions 
const actions = {
    loginSucceded(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>, payload: UserModel) {
        context.commit("SET_USER", payload);
        Sentry.setUser({ id: payload.id, email: payload.correoElectronico });
    },
    passwordRecoveryStep1(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>, payload: string) {
        context.commit("SET_USER_EMAIL", payload);
    },
    passwordRecoveryStep2(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>, token: string) {
        context.commit("SET_USER_PASSWORD_TOKEN", token);
    },
    pendingEmailConfirmation(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>, data: {
        email: string,
        userId: string
    }) {
        context.commit("SET_CONFIRMATION_EMAIL", data.email);
        context.commit("SET_CONFIRMATION_USER_ID", data.userId);
    },
    checkIfUserIsLoggedIn(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>) {
        return new Promise(async function (resolve, reject) {
            try {
                let cognitouser: CognitoUser = await Auth.currentAuthenticatedUser();
                //Check if user have been set
                if (!context.state.user) {
                    //Get User Attributes
                    //set user data
                    cognitouser.getUserAttributes((err, attributes) => {
                        var email = attributes?.find(x => x.Name == "email")?.Value as string
                        var name = attributes?.find(x => x.Name == "name")?.Value as string
                        var userId = attributes?.find(x => x.Name == "sub")?.Value as string 
                        var phoneNumber = attributes?.find(x => x.Name == "phone_number")?.Value as string 
                        var documentId = attributes?.find(x => x.Name == "preferred_username")?.Value as string 
                        var birthDate = attributes?.find(x => x.Name == "birthdate")?.Value as string 
                        var sexo = attributes?.find(x => x.Name == "gender")?.Value as string 
                        let userModel = new UserModel(userId, email, name, "", documentId, phoneNumber, birthDate, sexo);
                        context.dispatch("loginSucceded", userModel);
                        
                    });
                }
                context.dispatch("userSubcription").then(() => resolve(true))
            } catch (err) {
                console.log("error on get authenticated User", err);
                resolve(false)
            }
        })
    },
    async checkIfUserHasAccessToDashboard(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>) {
        return new Promise(async function (resolve, reject) {
            if (context.getters.getHasAccessToDashboard) resolve(true)
            else resolve(false)
        })
    },
    userSubcription(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>) {
        return new Promise(async (resolve, reject) => {
            let response = await identityService.UserSubscriptions();

            if (response.status === 200) {
                context.dispatch("Asset/setCurrencies", {}, { root: true })
                context.commit("SET_SUBSCRIPTIONS", (response.data as any).subscriptions);
                resolve((response.data as any).subscriptions);
            }
            reject([])
        })
        // identityService.UserSubscriptions().then(response => {
        //     if (response.status === 200) {
        //         context.commit("SET_SUBSCRIPTIONS", (response.data as any).subscriptions);
        //         return true;
        //     }
        // }).catch(error => {
        //     console.log(error);
        //     return false;
        // })
    },
    userInfoUpdated(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>, payload: UserModel) {
        context.commit("SET_USER", payload);
    },
    logOutUser(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>, payload: UserModel) {
        context.commit("SET_USER", null);
        context.commit("SET_SUBSCRIPTIONS", []);
        Sentry.configureScope(scope => scope.setUser(null));
    },
    getUserDetails(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>, userId: string) {
        return;
        identityService.UserDetails(userId).then((res: any) => {
            context.commit("SET_ACCOUNT_CREATED_DATE", res.data.entity["userCreateDate"]);
        })
    },
    async getCustomerPortalUrl(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>) {
        try {
            let response = await identityService.GetCustomerPortal();
            return response.data
        } catch (error) {
            console.log(error)
        }
    },
    async requestChangePlan(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>, planRequest: PlanRequest) {
        try {
            let response = await identityService.ChangePlans(planRequest);
            return response.data
        } catch (error) {
            console.log(error);
            return { checkout_url: '' } as PlanRequest
        }
    },
    clearData(context: ActionContext<IdentityManagmentStore, IdentityManagmentStore>) {
        context.commit('SET_INITIAL_STATE')
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export  { IdentityManagmentStore }