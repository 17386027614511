
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from 'vuex-class';
import UserModel from "@/models/IdentityManagement/UserModel";
import { mobileCheck } from "@/utils/HelperMethods";
import MobileNotSupported from "@/views/MobileNotSupported.vue";
import InstallationPrompt from "@/components/layout/InstallationPrompt.vue";
import { IdentityManagmentStore } from "@/store/modules/identityManagement.store";
import AddBankAccountModal from '@/components/assetsLiabilities/bankAccounts/AddBankAccountModal.vue';
import AddCreditCardModal from "@/components/assetsLiabilities/creditCard/AddCreditCardModal.vue"
import AddLoanModal from '@/components/assetsLiabilities/loan/AddLoanModal.vue'
import AddNewServiceModal from "@/components/assetsLiabilities/Services/AddNewServiceModal.vue"
import { ToastNotification } from "@/models/Notification/UserNotification";
import SelectProductModal from "./components/helpers/SelectProductModal.vue";
import Chart from "chart.js";

@Component({
  components: {
    MobileNotSupported,
    InstallationPrompt,
    AddBankAccountModal,
    AddCreditCardModal,
    AddLoanModal,
    AddNewServiceModal,
    SelectProductModal
  }
})
export default class App extends Vue {
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
  @Getter("Layout/getToastNotifications") notifications!: ToastNotification[];
  unsubscribeToActionEvent: any;
  budgetLastVisitedTab = 0
  budgetLastVisitedSubTab = 0

  get globalLoadingIsActive(): boolean {
    let allowAnonymous = this?.$route?.meta?.allowAnonymous;
    if (allowAnonymous)
      return !allowAnonymous;
    //Con esto nos aseguramos que la aplicacion no continue hasta que se llene la info del usuario
    //En el store
    let userIsLoggedIn = !!this.user;
    return !userIsLoggedIn;
  }

  @Watch("budgetIsLoading")
    budgetLoadingChange(value: any) {
      if (!value) {
        this.$store.commit("Budget/SET_BUDGET_TYPE_TAB", this.budgetLastVisitedTab);
        this.$store.commit("Budget/SET_LAST_VISITED_SUBTAB", this.budgetLastVisitedSubTab);
      }
    }

    @Watch("notifications")
    showNotifications(value: ToastNotification[]) {
      if (value && value.length > 0) {
        let notification = value[value.length - 1]
        let message = notification.detail ? notification.detail : 'Lo sentimos, ha ocurrido un error';
        this.$toast.open({type: notification.type, message: message, position: "top-right"});
      }
    }

  get isMobileDevice() {
    return mobileCheck();
  }

  loadChartPlugin() {
    Chart.pluginService.register({
      beforeDraw: function(chart: any) {
        if (chart.config.options.elements.center) {
          // Get ctx from string
          var ctx = chart.chart.ctx;

          // Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Arial';
          var txt = centerConfig.text;
          var color = centerConfig.color || '#000';
          var maxFontSize = centerConfig.maxFontSize || 75;
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          // Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = (chart.innerRadius * 2);

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
          var minFontSize = centerConfig.minFontSize;
          var lineHeight = centerConfig.lineHeight || 25;
          var wrapText = false;

          if (minFontSize === undefined) {
            minFontSize = 20;
          }

          if (minFontSize && fontSizeToUse < minFontSize) {
            fontSizeToUse = minFontSize;
            wrapText = true;
          }

          // Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          if (!wrapText) {
            ctx.fillText(txt, centerX, centerY);
            return;
          }

          var words = txt.split(' ');
          var line = '';
          var lines = [];

          // Break words up into multiple lines if necessary
          for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = ctx.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n] + ' ';
            } else {
              line = testLine;
            }
          }

          // Move the center up depending on line height and number of lines
          centerY -= (lines.length / 2) * lineHeight;

          for (var n = 0; n < lines.length; n++) {
            ctx.fillText(lines[n], centerX, centerY);
            centerY += lineHeight;
          }
          //Draw text in center
          console.log(centerY)
          ctx.fillText(line, centerX, centerY);
          ctx.fillText('Hello', centerX, -25);
        }
      }
    });
  }

  loadBudgetVisitedTab() {
    this.$root.$on('storeLastVisitedTab', (tab: number) => {
      this.budgetLastVisitedTab = tab
    });
    this.$root.$on('storeLastVisitedSubTab', (tab: number) => {
      this.budgetLastVisitedSubTab = tab
    });
  }
   
  mounted() {
    this.loadChartPlugin();

    // let divScripts = document.getElementById('app');
    // let newScript = document.createElement('script');
    // newScript.src = "//js-na1.hs-scripts.com/22178301.js";
    // divScripts?.appendChild(newScript);

    this.loadBudgetVisitedTab();
  }
}
