import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "@/configs/VeeValidate";
import "@/configs/Typpyjs";
import "@/configs/VueToastNotification";
import "@/configs/Sentry";
//
import { BootstrapVue } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//Configure Amplify Auth
import "@/configs/Amplify/init";
//
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
//
import moment from "moment";
moment.locale("es");
//
import money from 'v-money';
Vue.use(money, {precision: 4})
//
Vue.use(Loading);
Vue.component("loading", Loading);
//
//#region FilePond: https://pqina.nl/filepond/docs/patterns/frameworks/vue/
import 'filepond-polyfill';
// Import FilePond
import vueFilePond from 'vue-filepond';
// Import styles
import "filepond/dist/filepond.min.css";
// Create FilePond component
const FilePond = vueFilePond();
Vue.component('file-pond', FilePond);
//#endregion

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
//
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

import VueMask from 'v-mask'
Vue.use(VueMask);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
