// export default class BudgetCategory {
//     constructor(
//         public id: string,
//         public idOriginal: string,
//         public urlIcono: string,
//         public tipo: CategoryType,
//         public categoria: string,
//         public subcategorias: BudgetSubCategory[],
//         public categoryColor: string,
//         public fontColor: string,
//         public alias: string

import { Currency } from "../Common";

//     ) {}
// }

class UserCatalogSubCategoryRef {
    constructor(public subTipo: string,
                public tipo: string,
                public id: string) {
        
    }
}

export interface CatalogAux {
    category: string,
    executed: string,
    planned: string,
    color: string,
    perc: number,
    excluded: boolean
}

interface BudgetType {
    code: string;
    uuid: string;
    name: string;
}

interface BudgetCategory {
    uuid: string;
    subscription: string;
    budget: string;
    category: string;
    created_from_template?: Boolean;
    created_from_external?: Boolean;
    alias: string;
    subcategories: BudgetSubCategory[],
    type?: BudgetType | string;
    color?: string;
    categoryColor?: string;
    originalId?: string;
    name: string;
}

interface CatalogCategory {
    uuid: string;
    type: string;
    name: string;
    color: string;
    icon: string;
    font_color: string;
    transaction_type: string;
}

interface BudgetSubCategory {
    subscription: string;
    uuid: string;
    category: string;
    sub_category: string;
    budgeted: number;
    executed: number;
    created_from_template?: boolean;
    created_from_external?: boolean,
    rate: string;
    external_source?: string;
    external_id?: string;
    alias?: string;
    currency: Currency;
}

interface BudgetSubCategoryEdit {
    subscription: string;
    uuid: string;
    budgeted?: number;
    alias?: string;
    categoryId?: string;
}

interface BudgetTemplate {
    uuid: string;
    name?: string;
}

interface BudgetTemplateSubCategory {
    uuid: string;
    active: boolean;
    bt_category: string;
    sub_category: BudgetSubCategory
}

interface BudgetTemplateCategory {
    uuid: string;
    active: boolean;
    btcsubcategories: BudgetTemplateSubCategory[];
    category: BudgetCategory
}


enum CategoryType {
    Income = "I",
    Expense = "G",
    Saving = "A",
}
export { BudgetCategory, BudgetSubCategory, CategoryType, CatalogCategory, BudgetSubCategoryEdit, BudgetTemplate, BudgetTemplateCategory, BudgetTemplateSubCategory};