
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import { Service as ServiceUserCatalog } from "@/models/UserCatalog/UserCatalog"
import { ServiceType } from "@/models/Catalog/Catalog"
import { Currency } from '@/models/Common/Common'
import { ModalHelper } from "@/components/assetsLiabilities/mixins/index"
import UIkit from 'uikit';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { CurrencyInput, setValue }  from 'vue-currency-input';
import UserBudget from "@/models/Budget/UserBudget";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    CurrencyInput
  }
})
export default class AddNewServiceModal extends Mixins(ModalHelper) {
  currentService = {
    amt: 0.00
  } as ServiceUserCatalog
  selectedService = {} as ServiceType
  @Getter("Asset/getServices") services! : ServiceType[]
  @Getter("Asset/getCurrencies") currencies!: Currency[];
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;
  isEditing = false;
  emptyService = {} as ServiceType;
  showErrors = false;

  get getMoneyCode() {
    if (this.currentService && this.currentService.currency) {
        return this.currencies.find(c => c.uuid == this.currentService.currency)?.code.toUpperCase();
      }
      return ''
  }
  
  clearService() {
    this.selectedService = {} as ServiceType;
    this.currentService = {} as ServiceUserCatalog;
    this.isEditing = false;
    this.showErrors = false;
    (this.$refs['service-form'] as any).reset();
  }

  async validateForm(saveAndContinue=false) {
    const isFormValid = await (this.$refs['service-form'] as any).validate()

    if (!isFormValid || !this.selectedService.uuid) {
      this.showErrors = true;
      return;
    }

    if (isFormValid && !saveAndContinue) {
      this.saveService()
    }

    if (isFormValid && saveAndContinue) {
      this.saveAndContinue()
    }
  }

  formatService(service: ServiceUserCatalog) {
    return {
      ...service,
      service_type: this.selectedService.uuid
    }
  }

  created() {
    this.$root.$on('clearService', () => {
      this.clearService();
    });
    this.$root.$on('assignService', (serviceId: string) => {
      this.selectedService = this.services.filter(s => s.uuid === serviceId)[0];
    });
    this.$root.$on('editService', (editingService: ServiceUserCatalog) => {
      this.editService(editingService);
    });
  }

  saveService() {
    const serviceToSave = this.formatService(this.currentService);
    this.$store.dispatch("Asset/saveService", { payload: serviceToSave, isEditing: this.isEditing, userId: this.user.id });
    this.clearService();
    this.closeModal();
    setTimeout(() => {
      this.$store.dispatch("Dashboard/setDueServices");
      if (this.$route.name && this.$route.name.toLowerCase() == 'dashboard') this.$store.dispatch("Budget/setActiveBudget", this.activeBudget.uuid);
    }, 1000)
  }

  saveAndContinue() {
    const serviceToSave = this.formatService(this.currentService);
    this.$store.dispatch("Asset/saveService", { payload: serviceToSave, isEditing: this.isEditing, userId: this.user.id });
    this.clearService();
    this.closeModal();
    setTimeout(() => {
      if (this.$route.name && this.$route.name.toLowerCase() == 'dashboard') this.$store.dispatch("Budget/setActiveBudget", this.activeBudget.uuid);
      this.$store.dispatch("Dashboard/setDueServices");
      this.openModal()
    }, 1000);
  }

  closeModal() {
    UIkit.modal(document.getElementById('add-new-service-modal')).hide();
  }

  openModal() {
    UIkit.modal(document.getElementById('add-new-service-modal')).show();
  }

  editService(editingService: ServiceUserCatalog) : void {
    const serviceToBeEdited = {...editingService};
    const serviceTypeMap = this.services.filter((serv: ServiceType) => serv.uuid == (serviceToBeEdited.service_type as any).uuid)[0];
    this.selectedService = {...serviceTypeMap};
    this.currentService = {
      ...serviceToBeEdited,
      currency: (serviceToBeEdited.currency as any).uuid,
      amt: parseFloat(serviceToBeEdited.amt as any)
      };
    this.isEditing = true;
  }
}
