import Axios, { AxiosInstance, AxiosResponse }  from "axios";
import AppSettings from "@/AppSettings";
import BaseServiceResponse from "@/models/BaseServiceResponse";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import store from '@/store'
import router from "@/router";

export default class BaseApiService {
    public apiUrl: string;
    public $http: AxiosInstance;
    defaultHeaders: object;
    apiKey: string;
    public constructor() {
        this.apiUrl = AppSettings.API_URL;
        let apiKey = AppSettings.API_KEY;
        this.$http = Axios.create({
            baseURL: this.apiUrl,
            headers: {
                'Content-Type': 'application/json'
            }
          });
        this.apiKey = apiKey;
        this.defaultHeaders = {}
        this.$http.interceptors.request.use(this.addAuthToken);
        this.$http.interceptors.response.use(undefined, function(error) {
            if (error.response.status === 401 || error.response.status === 403) {
                Auth.signOut()
                    .then(res => {
                        store.dispatch("IdentityManagement/clearData");
                        store.dispatch('Asset/clearData');
                        store.dispatch('Budget/clearData');
                        store.dispatch('Dashboard/clearData');
                        router.push('/login');
                    })
            }

            if (error.response.status === 500) {
                store.dispatch("Layout/addOperationNotification", { type: 'error', detail: error.response.data.detail }, { root: true })
            } else if (error.response.status === 500) {
                store.dispatch("Layout/addOperationNotification", { type: 'error', detail: error.response.data.detail }, { root: true })
            }  else {
                store.dispatch("Layout/addOperationNotification", { type: 'error', detail: 'Lo sentimos, ha ocurrido un error.' }, { root: true })
            }        
            return Promise.reject(error);
        });
    }

    public async addAuthToken(request: any) {
        const route = request.url?.split('/').pop();

        return new Promise(async function(resolve, reject) {
            try {
                const cognitouser: CognitoUser = await Auth.currentAuthenticatedUser();
                const token = cognitouser.getSignInUserSession()?.getIdToken().getJwtToken();
                request.headers["Authorization"] = `Bearer ${token}`;
                resolve(request)
            } catch (err) {
                resolve(request)
            }
        });
    }

    public async post<T extends Object,RT>(action: string, body: T, files?: any): Promise<AxiosResponse<BaseServiceResponse<RT>>> {
        
        let headers = {
            ...this.defaultHeaders,
            'Content-Type': 'application/json'
        };
        if (files) {
            const formData = new FormData();
            for (const key in body) {
                if (body.hasOwnProperty(key)) {
                    formData.append(key, JSON.stringify(body[key]));
                }
            }

            for (const key in files) {
                if (files.hasOwnProperty(key) && Array.isArray(files[key])) {
                    files[key].forEach((file: File, i: number) => {
                        formData.append(`${key}[${i}]`, file);
                    });
                } else {
                    console.error('Files object property is not an array of files');
                }
            }

            headers = {
                ...this.defaultHeaders,
                'Content-Type': 'multipart/form-data'
            };
            (body as any) = formData;
        }

        // // return Axios({ method: 'post', url: this.apiUrl + action, headers: headers })
        return this.$http.post(this.apiUrl + action, body);
    }

    public async patch<T extends Object,RT>(action: string, body: T, id: string, subscription?: string, files?: any): Promise<AxiosResponse<BaseServiceResponse<RT>>> {
        
        let headers = {
            ...this.defaultHeaders,
            'Content-Type': 'application/json'
        };

        if (files) {
            const formData = new FormData();
            for (const key in body) {
                if (body.hasOwnProperty(key)) {
                    formData.append(key, JSON.stringify(body[key]));
                }
            }

            for (const key in files) {
                if (files.hasOwnProperty(key) && Array.isArray(files[key])) {
                    files[key].forEach((file: File, i: number) => {
                        formData.append(`${key}[${i}]`, file);
                    });
                } else {
                    console.error('Files object property is not an array of files');
                }
            }

            headers = {
                ...this.defaultHeaders,
                'Content-Type': 'multipart/form-data'
            };
            (body as any) = formData;
        }

        // // return Axios({ method: 'post', url: this.apiUrl + action, headers: headers })
        return this.$http.patch(this.apiUrl + action + id + '/?subscription=' + subscription, body);
    }

    public async delete<T extends Object,RT>(action: string, id: string, subscription?: string, files?: any): Promise<AxiosResponse<BaseServiceResponse<RT>>> {
        
        let headers = {
            ...this.defaultHeaders,
            'Content-Type': 'application/json'
        };

        // // return Axios({ method: 'post', url: this.apiUrl + action, headers: headers })
        return this.$http.delete(this.apiUrl + action + id + `/?subscription=${subscription}`);
    }

    public async get<T extends Object,RT>(controller: string, queryParams?: T) : Promise<AxiosResponse<BaseServiceResponse<RT>>>{
        let headers = {
            ...this.defaultHeaders,
            'Content-Type': 'application/json'
        };
        return this.$http.get(this.apiUrl + controller, {
            params: { queryParams },
            headers: headers
        })
    }

    public async retrieve<T extends Object,RT>(controller: string, objectId: string, subscription: string, queryParams?: T) : Promise<AxiosResponse<BaseServiceResponse<RT>>>{
        let headers = {
            ...this.defaultHeaders,
            'Content-Type': 'application/json'
        };
        return this.$http.get(this.apiUrl + controller + objectId + "/?subscription=" + subscription, {
            params: { queryParams },
            headers: headers
        })
    }
}