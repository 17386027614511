export default class UserModel {
    constructor(
        public id: string,
        public correoElectronico: string,
        public nombre: string,
        public profilePicture: string,
        public documentId?: string,
        public telefono?: string,
        public fechaNacimiento?: string,
        public sexo?: string,
        public currency?: string
    ) { }
}