import { ActionContext } from "vuex";
import { UserNotification, ToastNotification } from "@/models/Notification/UserNotification";
import {mobileCheck} from "@/utils/HelperMethods";

interface BudgetLayoutStore {
    userNotifications: UserNotification[],
    toastNotifications: ToastNotification[]
}
// State object
const state: BudgetLayoutStore = {
    userNotifications: [],
    toastNotifications: []
}

// Getter functions
const getters = {
    getUserNotifications(state: BudgetLayoutStore) {
        return state.userNotifications;
    },
    getToastNotifications(state: BudgetLayoutStore) {
        return state.toastNotifications;
    },
    isMobileView(state: BudgetLayoutStore) {
        return mobileCheck();
    }
}
// Mutations
const mutations = {
    SET_USER_NOTIFICATIONS(state: BudgetLayoutStore, data: UserNotification[]) {
        state.userNotifications = data;
    },
    SET_TOAST_NOTIFICATION(state: BudgetLayoutStore, data: ToastNotification) {
        state.toastNotifications.push(data)
    },
    CLEAR_TOAST_NOTIFICATION(state: BudgetLayoutStore) {
        state.toastNotifications = []
    }
}

// Actions 
const actions = {
    notificationsLoaded(context: ActionContext<BudgetLayoutStore, BudgetLayoutStore>, payload: UserNotification[]) {
        context.commit("SET_USER_NOTIFICATIONS", payload);
    },
    addOperationNotification(context: ActionContext<BudgetLayoutStore, BudgetLayoutStore>, payload: ToastNotification) {
        if (context.getters.getToastNotifications.length > 5) context.commit("CLEAR_TOAST_NOTIFICATION", payload);
        context.commit("SET_TOAST_NOTIFICATION", payload);
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}