import { ActionContext } from "vuex";
import DashboardService from "@/services/DashboardService";
import BudgetTransaction from "@/models/Budget/BudgetTransaction";
import { GeneralBalance, Patrimony, DueService, BehaviourInTime, Event, TransactionRedirection } from '@/models';
import moment, { tz } from "moment-timezone";
import UpcomingEvent from "@/components/dashboard/Event.vue";
import { getActiveTransaction } from "@sentry/tracing";

const COMPONENT_NUM_IN_DASHBOARD_VIEW = 7

interface DashboardStore {
    balance: GeneralBalance,
    patrimony: Patrimony,
    financialHealth: number | null,
    dueServices: DueService[],
    behaviourInTime: BehaviourInTime[]
    transactions: BudgetTransaction[],
    globalTransactions: BudgetTransaction[],
    productModalFromDashboard: TransactionRedirection,
    events: Event[],
    loadingEventUrl: boolean,
    loadingTransactionTable: boolean,
    dashboardComponentLoaded: String[]
}

const dashboardService = new DashboardService();

function initialState(): DashboardStore {
    return {
        balance: { total_available: 0, incomes: 0, expenses: 0} as GeneralBalance,
        patrimony: {} as Patrimony,
        financialHealth: null,
        dueServices: [],
        behaviourInTime: [],
        transactions: [],
        globalTransactions: [],
        productModalFromDashboard: {redirect: false, to: ''} as TransactionRedirection,
        events: [],
        loadingEventUrl: false,
        dashboardComponentLoaded: [],
        loadingTransactionTable: false
    }
}

const state = initialState();

const getters = {
    getCurrentBalance(state: DashboardStore) {
        return state.balance
    },
    getAvailableBalance(state: DashboardStore) {
        return state.balance.total_available
    },
    getExpenseBalance(state: DashboardStore) {
        return state.balance.expenses
    },
    getIncomeBalance(state: DashboardStore) {
        return state.balance.incomes
    },
    getAssets(state: DashboardStore) {
        return state.patrimony.assets
    },
    getLiabilities(state: DashboardStore) {
        return state.patrimony.liabilities
    },
    getPatrimonyTotal(state: DashboardStore) {
        return state.patrimony.net_patrimony
    },
    getFinancialHealthScore(state: DashboardStore) {
        return state.financialHealth
    },
    getDueServices(state: DashboardStore) {
        return state.dueServices
    },
    getDashboardTransactions(state: DashboardStore) {
        return state.transactions
    },
    getGlobalTransactions(state: DashboardStore) {
        return state.globalTransactions
    },
    getProductModalCalledFromDashboard(state: DashboardStore) {
        return state.productModalFromDashboard;
    },
    getNextEvents(state: DashboardStore) {
        return state.events;
    },
    isLoadingEventUrl(state: DashboardStore) {
        return state.loadingEventUrl;
    },
    isDashboardLoaded(state: DashboardStore) {
        const compLoaded = state.dashboardComponentLoaded.length;
        return compLoaded == COMPONENT_NUM_IN_DASHBOARD_VIEW
    },
    isTransactionTableLoading(state: DashboardStore) {
        return state.loadingTransactionTable
    },
    getGlobalTransactionMap(state: DashboardStore, getters: any) {
        if (state.globalTransactions) {
            return getters.getGlobalTransactions.map((t: BudgetTransaction) => {
                let total = typeof t.amt == 'string' ? parseFloat(t.amt) : t.amt;
                if ((t.currency as any).code.toLowerCase() != 'dop') {
                    let rate = typeof t.rate == 'string' ? parseFloat(t.rate) : t.rate;
                    total = total * rate
                }
                return {
                    date: t.date.substring(0, 7),
                    income: t.type.toLowerCase() == 'i' ? total : 0,
                    expenses: t.type.toLowerCase() == 'g' ? total : 0,
                    type: t.type.toLowerCase() == 'i' ? 'i' : 'g'
                } as BehaviourInTime
            })
        }
        return []
    },
    getExpensesGlobalTransaction(state: DashboardStore) {
        if (state.globalTransactions) {
            return []
        }
        return []
    },
    getBehaviourInTime(state: DashboardStore, getters: any) {
        if (state.globalTransactions.length) {
            return getMonthsForBehaviorInTime().map(month => {
                let incomeMonthTransactions = getters.getGlobalTransactionMap
                                        .filter((t: BehaviourInTime) => t.date == month && t.type == 'i')
                                        .map((t: BehaviourInTime) => t.income);
                let incomeTotal = 0;
                if (incomeMonthTransactions.length) incomeTotal = incomeMonthTransactions.reduce((a: number, b: number) => a+b)
                
                let expenseMonthTransactions = getters.getGlobalTransactionMap
                                        .filter((t: BehaviourInTime) => t.date == month && t.type == 'g')
                                        .map((t: BehaviourInTime) => t.expenses);
                let expenseTotal = 0;
                if (expenseMonthTransactions.length) expenseTotal = expenseMonthTransactions.reduce((a: number, b: number) => a+b)

                return {
                    date: month,
                    income: incomeTotal,
                    expenses: expenseTotal
                } as BehaviourInTime
            })
        } 
        return []
    }
}

const mutations = {
    SET_GENERAL_BALANCE(state: DashboardStore, data: GeneralBalance) {
        state.balance = data;
    },
    SET_PATRIMONY(state: DashboardStore, data: Patrimony) {
        state.patrimony = data;
    },
    SET_FINANCIAL_HEALTH_SCORE(state: DashboardStore, data: number | null) {
        state.financialHealth = data;
    },
    SET_DUE_SERVICES(state: DashboardStore, data: DueService[]) {
        state.dueServices = data;
    },
    SET_BEHAVIOUR_IN_TIME(state: DashboardStore, data: BehaviourInTime[]) {
        state.behaviourInTime = data;
    },
    SET_TRANSACTIONS(state: DashboardStore, data: BudgetTransaction[]) {
        state.transactions = data;
    },
    SET_GLOBAL_TRANSACTIONS(state: DashboardStore, data: BudgetTransaction[]) {
        state.globalTransactions = data;
    },
    SET_PRODUCT_MODAL_FROM_DASHBOARD(state: DashboardStore, data: TransactionRedirection) {
        state.productModalFromDashboard = data
    },
    SET_INITIAL_STATE(state: DashboardStore) {
        Object.assign(state, initialState());
    },
    SET_EVENTS(state: DashboardStore, data: Event[]) {
        state.events = data;
    },
    SET_EVENT_URL_LOAD(state: DashboardStore) {
        state.loadingEventUrl = !state.loadingEventUrl;
    },
    SET_TRANSACTION_TABLE_LOADING(state: DashboardStore) {
        state.loadingTransactionTable = false;
    },
    SET_LOADED_COMPONENT(state: DashboardStore, data: String) {
        if (!state.dashboardComponentLoaded.includes(data)) state.dashboardComponentLoaded.push(data)
    }
}

const actions = {
    setComponentAsLoaded(context: ActionContext<DashboardStore, DashboardStore>, component: string) {
        context.commit('SET_LOADED_COMPONENT', component)
    },
    clearData(context: ActionContext<DashboardStore, DashboardStore>) {
        context.commit('SET_INITIAL_STATE')
    },
    updateDashboard(context: ActionContext<DashboardStore, DashboardStore>, payload: { userId: string, moneda: string }) {
        context.dispatch('setGeneralBalance');
        context.dispatch('setPatrimony');
        context.dispatch('setDashboardTransactions');
        context.dispatch('setGlobalTransactions');
        context.dispatch('setDueServices');
    },
    setGeneralBalance(context: ActionContext<DashboardStore, DashboardStore>) {
        dashboardService.GetGeneralBalance()
            .then(res => {
                context.commit("SET_GENERAL_BALANCE", res.data)
            }).catch(err => {
                console.log(err);
            })
            .finally(() => {
                context.dispatch('setComponentAsLoaded', 'dashboard_balance');
            })
    },
    setPatrimony(context: ActionContext<DashboardStore, DashboardStore>) {
        dashboardService.GetPatrimony()
            .then(res => {
                context.commit("SET_PATRIMONY", res.data);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                context.dispatch('setComponentAsLoaded', 'dashboard_patrimony');
            })
    },
    setBehaviourInTime(context: ActionContext<DashboardStore, DashboardStore>, payload: { userId: string, moneda: string }) {
        dashboardService.GetBehaviourInTime(payload.userId, payload.moneda)
            .then(res => {
                context.commit("SET_BEHAVIOUR_IN_TIME", res.data.entity)
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                context.dispatch('setComponentAsLoaded', 'dashboard_behaviour');
            })
    },
    setDashboardTransactions(context: ActionContext<DashboardStore, DashboardStore>) {
        dashboardService.GetUserDashboardTransactions()
            .then(res => {
                context.commit("SET_TRANSACTIONS", res.data)
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                context.dispatch('setComponentAsLoaded', 'dashboard_transaction_table');
                context.dispatch('setComponentAsLoaded', 'dashboard_behaviour');
            })
    },
    setGlobalTransactions(context: ActionContext<DashboardStore, DashboardStore>) {
        dashboardService.GetGlobalTransactions()
            .then(res => {
                context.commit("SET_GLOBAL_TRANSACTIONS", res.data)
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                context.dispatch('setComponentAsLoaded', 'dashboard_transaction_table');
            })
    },
    setDueServices(context: ActionContext<DashboardStore, DashboardStore>) {
        dashboardService.GetDueServices()
            .then(res => {
                context.commit("SET_DUE_SERVICES", res.data)    
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                context.dispatch('setComponentAsLoaded', 'dashboard_services');
            })
    },
    setFinancialHealth(context: ActionContext<DashboardStore, DashboardStore>, payload: string) {
        dashboardService.GetFinancialHealth(payload)
            .then(res => {
                context.commit("SET_FINANCIAL_HEALTH_SCORE", (res.data as any).score)
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                context.dispatch('setComponentAsLoaded', 'financial_health');
            })
    },
    setEvents(context: ActionContext<DashboardStore, DashboardStore>) {
        dashboardService.GetEvents()
            .then(res => {
                const tzEvents = (res.data as any).map((e: Event) => {
                    return {
                        ...e,
                        date: new Date(e.date).toLocaleString("es-DO", {year: 'numeric', month: 'numeric', day: 'numeric'}),
                        hour: formatTime(e.hour, e.timezone)
                    }
                })
                context.commit("SET_EVENTS", tzEvents)

                for (let e of tzEvents) {
                    context.dispatch('getEventRegistrationURL', e.id)
                }

            }).catch(err => {
                console.log(err);
            }).finally(() => {
                context.dispatch('setComponentAsLoaded', 'dashboard_events');
            })
    },
    getEventRegistrationURL(context: ActionContext<DashboardStore, DashboardStore>, payload: string) {
        const currentEvents = context.getters.getNextEvents.filter((e: Event) => e.id !== payload);;
        const selectedEvent = context.getters.getNextEvents.filter((e: Event) => e.id === payload)[0];

        if (!selectedEvent.urlParaRegistrar) {
            context.commit("SET_EVENT_URL_LOAD");

            dashboardService.GetEventRegistrationLink(payload)
                .then(res => {
                    const updatedEvent = {
                        ...selectedEvent,
                        register_url: (res.data as any).register_url
                    } as Event
                    currentEvents.push(updatedEvent);
                    context.commit("SET_EVENTS", currentEvents)
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    context.commit("SET_EVENT_URL_LOAD");
                })
        }
        
    },
    setProductCallFromDashboard(context: ActionContext<DashboardStore, DashboardStore>, payload: TransactionRedirection) {
        context.commit("SET_PRODUCT_MODAL_FROM_DASHBOARD", payload);
    },
    updateLoadingStatus(context: ActionContext<DashboardStore, DashboardStore>, payload: string) {
        const map = {
            table: 'SET_TRANSACTION_TABLE_LOADING'
        }
        const choice = (map as any)[payload];    
        context.commit(choice);
    },
    
}

function formatTime(time: string, tz: string) {
    const webTime = new Date(time).toLocaleString("es-DO", {hour: '2-digit', minute: '2-digit'});
    const isPM = webTime.includes('p')
    const timeDivisor = webTime.indexOf(':');
    let numericTime = webTime.slice(0, 5)

    if (isPM) {
        let hoursOffSet = parseInt(webTime.slice(0, timeDivisor)) + 12;
        numericTime = `${hoursOffSet}:${webTime.slice(timeDivisor + 1, timeDivisor + 3)}`
    }
    const UTCTime = moment.utc(numericTime, 'HH:mm').tz(tz).format('HH:mm');
    return isPM ? `${parseInt(UTCTime.slice(0, 3)) - 12}:${UTCTime.slice(3)} PM` : UTCTime + 'AM'
}

function getMonthsForBehaviorInTime() {
    let months = [];
      let currentDate = moment().locale('es')
      let monthsToSubstract = 0;
      if (currentDate.format('DD') === '01' || moment().format('MM') !== currentDate.format('MM')) {
          monthsToSubstract = 1;
          if (moment().format('MM') === currentDate.format('MM')) {
              currentDate = currentDate.subtract(1, 'M').endOf('M');
          }
      }
      months.push(... [...Array(24)].map((_, mm) => {
          const month = moment().locale('es').subtract(monthsToSubstract, 'M').subtract(mm, 'M');
          return month.format('YYYY-MM');
      }).reverse());

      return months;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}